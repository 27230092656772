<template>
<div class="layout-page" ref="indexWarp">
    <!-- 顶栏 -->
    <div class="header">
      <div class="logo">
        <img src="@/assets/layout/logo.png" alt="logo" />
      </div>
      <div class="nav">
        <ol class="nav-list">
          <li
            :class="['nav-item', activeIndex === item.value ? 'actived' : '']"
            v-for="item in navList"
            :key="item.value"
            @click="onChaneNav(item)"
          >
            {{ item.label }}
          </li>
        </ol>
      </div>
      <div class="login">
        <el-button class="btn gradient-btn" @click="$router.push('/login')">注册/登陆</el-button>
      </div>
    </div>
    <div class="content">
      <index-warp />
    </div>
    <div class="footer">
      <div class="start">
        <h1 class="title">现在就免费开始吧</h1>
        <el-button class="gradient-btn btn" @click="$router.push('/login')">立即创作</el-button>
      </div>
      <div class="bottom">
        <div class="logo">
          <img src="@/assets/layout/logo.png" alt="logo" />
        </div>
        <p class="name">AI视频创作平台</p>
        <div class="info">
          <p>联系方式：tianjianhong@admaxmobile.com</p>
          <p>备案号：沪ICP备<a href="https://beian.miit.gov.cn/">2023021759</a>号</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import indexWarp from '@/components/indexWarp.vue'
export default {
  name: 'IndexWrapper',
  components: {
    indexWarp
  },
  data () {
    return {
      navList: [
        {
          value: 1,
          label: '首页',
          link: ''
        },
        {
          value: 2,
          label: '联系我们',
          link: '/login'
        }
      ],
      activeIndex: 1,
    }
  },
  mounted () {
  },
  methods: {
    onChaneNav (item) {
      if (item.value === 2) {
        document.getElementById('contacOurs').scrollIntoView({ behavior: 'smooth', block: 'center' })
      } else {
        this.$router.push(item.link)
      }
      this.activeIndex = item.value
    },
  },
}
</script>

<style lang="less" scoped>
.layout-page {
  // width: 1200px;
  width: auto;
  margin: 0 auto;
  user-select: none;

  > .header {
    display: flex;
    align-items: center;
    padding: 0 200px;
    height: 40px;
    background: transparent;
    // border-bottom: 1px solid #ccc;
    > .logo {
      width: 100px;
      margin-right: 20px;
      img {
        width: 100%;
      }
    }

    > .nav {
      flex: 1;
      .nav-list {
        display: flex;
        flex-direction: row;
        list-style: none;
        > .nav-item {
          color: var(--text-default);
          margin: 0 12px;
          cursor: pointer;
          font-weight: 500;
        }
        > .actived {
          color: var(--text-active);
          font-weight: 700;
          border-bottom: 3px solid var(--text-active);
        }
      }
    }
    > .login .btn {
      width: 80px;
      height: 25px;
      font-size: 10px;
      padding: 0;
    }
  }

  > .footer {
    box-sizing: border-box;
    height: 490px;
    padding-top: 130px;
    background:
      url('@/assets/layout/icon-1.png') no-repeat right 100px top 80px / 20%,
      url('@/assets/layout/icon-2.png') no-repeat left 300px bottom / 20%,
      url('@/assets/layout/footer.png') no-repeat bottom / 100%;

    .start {
      text-align: center;
      .title {
        font-size: 38px;
      }
      .btn {
        margin-top: 40px;
        width: 110px;
      }
    }
    .bottom {
      display: flex;
      align-items: start;
      color: var(--text-default);
      margin: 120px 250px 0;
      > .logo {
        width: 100px;
        margin-right: 35px;
        img {
          width: 100%;
        }
      }
      > .name {
        flex: 1;
        font-size: 16px;
        font-weight: 700;
      }
      > .info {
        p {
          font-size: 10px;
          line-height: 20px;
        }
      }
    }
  }
}
</style>
