<template>
  <div>
    <audio
      @timeupdate="updateProgress"
      controls
      ref="audioRef"
      id="myAudio"
      style="display: none"
    >
      <source :src="fileurl" type="audio/mpeg" />
      {{ $t('YourBrowserDoesNotSupportAudioPlayback') }}
    </audio>
    <div class="audio-right">
      <i
        :class="
          audioStatus !== 'pause'
            ? 'el-icon-video-play'
            : 'el-icon-video-pause'
        "
        @click="playAudio"
        class="dialogAudioPlay"
      ></i>
      <!-- <div class="progress-bar-bg" id="progressBarBg" v-dragto="setAudioIcon">
        <div class="progress-bar" id="progressBar"></div>
      </div> -->
      <div class="audio-bg" @click="handleSelect()">
        <ul>
          <li v-for="item in 30" :key="item"></li>
        </ul>
      </div>
      <div class="audio-time" style="min-height: 10px" @click="handleSelect()">
        <!-- <span class="audio-length-current" id="audioCurTime">{{
          audioStart
        }}</span>/ -->
        <span class="audio-length-total">{{ `${duration}"` }}</span>
      </div>
      <!-- <div class="volume">
        <div>
        </div>
        <i
          class="iconfont pl-1"
          :class="audioIcon"
          @click.stop="audioHuds = !audioHuds"
        >
        </i>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fileurl: {
      trpe: String,
      default: ''
    },
    item: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      audioStatus: 'play',
      audioStart: '0:00',
      duration: '0',
      audioVolume: 0.5,
      audioHuds: false
    }
  },
  directives: {
    dragto: {
      inserted: function (el, binding, vnode) {
        el.addEventListener(
          'click',
          (e) => {
            const wdiv = document.getElementById('progressBarBg').clientWidth
            const audio = vnode.context.$refs.audioRef
            // const audio = document.getElementById('myAudio')
            console.log('audio', audio)
            // 只有音乐开始播放后才可以调节，已经播放过但暂停了的也可以
            const ratemin = e.offsetX / wdiv
            const rate = ratemin * 100
            document.getElementById('progressBar').style.width = rate + '%'
            audio.currentTime = audio.duration * ratemin
            audio.play()
            binding.value()
          },
          false
        )
      }
    },
  },
  computed: {
    audioIcon () {
      if (this.audioHuds) {
        return this.audioVolume < 0.01
          ? 'checked icon-jingyin'
          : 'checked icon-shengyin'
      } else {
        return 'icon-shengyin'
      }
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      const that = this
      const myVid = this.$refs.audioRef
      // const myVid = document.getElementById('myAudio')
      myVid.loop = false
      // 监听音频播放完毕
      myVid.addEventListener(
        'ended',
        function () {
          that.audioStatus = 'play' // 显示播放icon
          // document.getElementById('progressBar').style.width = '0%' // 进度条初始化
        },
        false
      )
      if (myVid != null) {
        myVid.oncanplay = function () {
          that.duration = that.transTime(myVid.duration) // 计算音频时长
        }
        myVid.volume = 0.5 // 设置音量50%
      }
    },
    // 播放暂停控制
    playAudio () {
      const recordAudio = this.$refs.audioRef // 获取audio元素
      // const recordAudio = document.getElementById('myAudio')
      if (recordAudio.paused) {
        recordAudio.play()
        this.audioStatus = 'pause'
      } else {
        recordAudio.pause()
        this.audioStatus = 'play'
      }
    },
    // 更新进度条与当前播放时间
    updateProgress (e) {
      if (e.target.currentTime === e.target.duration) {
        this.audioStatus = 'pause'
      }

      // this.audioStart = this.transTime(this.$refs.audioRef.currentTime)
      // this.audioStart = this.transTime(document.getElementById('myAudio').currentTime)
    },
    /**
     * 音频播放时间换算
     * @param {number} value - 音频当前播放时间，单位秒
     */
    transTime (time) {
      // const duration = parseInt(time)
      // let minute = parseInt(duration / 60)
      // let sec = (duration % 60) + ''
      // const isM0 = ':'
      // if (minute === 0) {
      //   minute = '00'
      // } else if (minute < 10) {
      //   minute = '0' + minute
      // }
      // if (sec.length === 1) {
      //   sec = '0' + sec
      // }
      // return minute + isM0 + sec
      return parseInt(time)
    },
    setAudioIcon () {
      this.audioStatus = 'pause'
    },
    handleSelect () {
      this.$emit('confirm', this.item)
    }
  }
}
</script>

<style lang="less" scoped>
.volume {
  position: relative;

  .volume-progress {
    position: absolute;
    top: -150px;
    width: 32px;
    height: 140px;
    background: #f6f6f6;
    border-radius: 4px;
    padding-top: 10px;
  }

  .volume-bar-bg {
    margin: 0 auto;
    width: 6px;
    height: 120px;
    background: #dcdcdc;
    border-radius: 100px;
    flex: 1;
    position: relative;
    transform: rotate(180deg);
    cursor: pointer;

    .volume-bar {
      width: 6px;
      height: 50%;
      background: #56bf8b;
      border-radius: 100px;
    }
  }

  .checked {
    color: #56bf8b;
  }
}

.audio-right {
//   width: 100%;
  height: 49px;
  line-height: 49px;
  background: #729CD5;
  border-radius: 10px;
  box-shadow: 0px 6px 10px 0px rgba(153,164,179,0.19);
  display: flex;
  align-items: center;
  padding: 0 10px;

  .dialogAudioPlay {
    cursor: pointer;
    color: #fff;
    font-size: 20px;
  }

  .progress-bar-bg {
    background-color: #fff;
    flex: 1;
    position: relative;
    height: 10px;
    top: 50%;
    transform: translateY(-50%);
    margin-top: -1px;
    cursor: pointer;
    margin: 0 10px;
  }

  .audio-bg {
    // flex: 1;
    width: 80%;
    margin: 0 2px;
    overflow: hidden;
    ul {
      list-style: none;
      display: flex;
      align-items: center;
      li {
        height: 17px;
        width: 0;
        border: 3px solid #FFFFFF;
        margin: 0 2px;
        border-radius: 10px;
      }
      li:nth-of-type(2n+1) {
        height: 14px;
      }
    }
  }

  .progress-bar {
    background-color: #56bf8b;
    width: 0%;
    height: 10px;
    border-radius: 5px;
  }

  .audio-time {
    overflow: hidden;
    font-size: 14px;
    width: 30px;

    .audio-length-total {
      color: #fff;
      float: right;
      font-weight: bold;
    }

    .audio-length-current {
      float: left;
    }
  }
}
</style>
