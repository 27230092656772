import { render, staticRenderFns } from "./AiDialog.vue?vue&type=template&id=617786fb&scoped=true"
import script from "./AiDialog.vue?vue&type=script&lang=js"
export * from "./AiDialog.vue?vue&type=script&lang=js"
import style0 from "./AiDialog.vue?vue&type=style&index=0&id=617786fb&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "617786fb",
  null
  
)

export default component.exports