<template>
  <el-dialog :title="$t('SoundSquare')" class="soundSelect"
    :show-close='false'
    :visible.sync="dialogVisible" width="50%">
    <div slot="title">
        <el-row>
        <el-col :span="8">
          <h2>{{ $t('SoundSquare') }}</h2>
        </el-col>
        <el-col :span="16" class="sexSelect">
          <el-select v-model="data.Gender" clearable filterable :placeholder="$t('Sex')" @change="queryLanguage('Sex', true)">
            <el-option
              v-for="(item, index) in sexOptions"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select v-model="data.language" clearable filterable :placeholder="$t('Languages')" @focus="focusPopover" @change="queryLanguage('Languages', true)">
            <el-option
              key="all"
              label="ALL"
              value="ALL"
            >
            </el-option>
            <el-option
              v-for="(item, index) in languageOptions"
              :key="index"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
          <el-select v-model="data.dialect" clearable filterable :placeholder="$t('OralSpeechSounds')" @focus="focusPopover" @change="queryLanguage('OralSpeechSounds', true)">
            <el-option
              key="all"
              label="ALL"
              value="ALL"
            >
            </el-option>
            <el-option
              v-for="(item, index) in dialectList"
              :key="index"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
          <el-popover
            placement="bottom-end"
            ref="mergePopover"
            width='355'
            style='flex: 1;'
            trigger="click">
            <div class="popverSelect">
              <el-row>
                <p>{{ $t('Role') }}</p>
                <el-checkbox-group
                  v-model="data.role"
                  @change="queryLanguage('role', false)"
                >
                  <el-checkbox v-for="(item, index1) in rolePlayList" :label="item" :key="index1">{{item}}</el-checkbox>
                </el-checkbox-group>
              </el-row>
              <el-row>
                  <p>{{ $t('Style') }}</p>
                  <el-checkbox-group
                    v-model="data.style"
                    @change="queryLanguage('style', false)"
                  >
                    <el-checkbox v-for="(item, index1) in styleList" :label="item" :key="index1">{{item}}</el-checkbox>
                  </el-checkbox-group>
              </el-row>
            </div>
            <el-button slot="reference">{{ $t('Other') }}</el-button>
          </el-popover>
        </el-col>
      </el-row>
    </div>
    <div class="soundWrap">
        <el-row class="lanCard">
          <el-col v-for="(item, index) in queryLanguageList" :key="index" class="text item" :span='8'>
                <el-card :class="{'box-card': true, active: langName === item.ShortName}" @click.native="selectLang(item)">
                    <el-row>
                        <el-col>
                            <!-- <el-image :src="item.img"></el-image> -->
                            {{ item.ShortName }}
                        </el-col>
                        <!-- <el-col :span='2'>
                            <i class="el-icon-caret-right"></i>
                        </el-col> -->
                    </el-row>
                    <p>
                        <el-tag v-if="item.Gender"> {{ item.Gender }} </el-tag>
                        <el-tag v-if="item.language"> {{ item.language }} </el-tag>
                        <!-- <el-tag v-for="(list, index1) in item.StyleList" :key="'s' + index1">
                            {{ list }}
                        </el-tag>
                        <el-tag v-for="(list, index1) in item.RolePlayList" :key="'r' + index1">
                            {{ list }}
                        </el-tag> -->
                        <el-tag v-if="data.style.length > 0">
                            {{ data.style[0] }}
                        </el-tag>
                        <el-tag v-if="data.role.length > 0">
                            {{ data.role[0] }}
                        </el-tag>
                    </p>
                </el-card>
          </el-col>
      </el-row>
    </div>
    <span slot="footer" class="dialog-footer">
      <!-- <el-button @click="dialogVisible = false">关 闭</el-button>
      <el-button type="primary" @click="submit()">确 定</el-button> -->
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    language: {
      type: Array,
      default: () => {
        return []
      }
    },
    langName: {
      type: String,
      default: ''
    },
  },
  data () {
    return {
      dialogVisible: false,
      selectLangItem: {},
      data: {
        Gender: '',
        language: '',
        dialect: '',
        role: [],
        style: [],
      },
      sexOptions: [
        {
          value: 'Male',
          label: this.$t('Male')
        },
        {
          value: 'Female',
          label: this.$t('Female')
        }
      ],
      languageOptions: [],
      checkedCities: '',
      dialectList: [],
      rolePlayList: [],
      styleList: [],
      queryLanguageList: [],
      allLanguageOptions: [],
      allDialectList: []
    }
  },
  watch: {
    value (val) {
      this.dialogVisible = val
      if (val) {
        console.log(val)
        this.selectLangItem = {}
        this.languageOptions = []
        this.dialectList = []
        this.rolePlayList = []
        this.styleList = []
        this.data = {
          Gender: '',
          language: '',
          dialect: '',
          role: [],
          style: [],
        }
        this.querySearch(this.language, 'Sex', true)
        this.queryLanguage(true)
      }
    },
    dialogVisible (val) {
      this.$emit('input', val)
    }
  },
  mounted () {
  },
  methods: {
    /**
     * 描述 筛选搜索条件的过滤
     * @date 2023-09-15
     * @param {any} queryLanguageList 语言列表
     * @param {any} type 筛选 点击的type
     * @param {any} bool 角色 风格 是否参与联动
     * @returns {any}
     */
    querySearch (queryLanguageList, type, bool) {
      const languageOptions = []
      const dialectList = []
      let rolePlayList = []
      let styleList = []
      queryLanguageList.forEach(item => {
        item.RolePlayList = item.RolePlayList ?? []
        item.StyleList = item.StyleList ?? []
        languageOptions.push(item.language)
        dialectList.push(item.dialect)
        rolePlayList = [
          ...rolePlayList,
          ...item.RolePlayList
        ]
        styleList = [
          ...styleList,
          ...item.StyleList
        ]
      })
      // // 点击语种的时候  语种有值 不参与联动
      // if ((type !== 'Languages') || bool) {
      //   this.languageOptions = [...new Set(languageOptions)]
      // }
      // // 点击口音的时候  口音有值 不参与联动
      // if ((type !== 'OralSpeechSounds') || bool) {
      //   this.dialectList = [...new Set(dialectList)]
      // }
      // 所有的 角色 风格
      if (bool) {
        this.allLanguageOptions = Object.assign({}, [...new Set(languageOptions)])
        this.allDialectList = Object.assign({}, [...new Set(dialectList)])
      }
      // 选择所有的时候 取所有的数据
      if (this.data.language === 'ALL' && type === 'Languages') {
        this.data.language = ''
        // this.languageOptions = Object.assign({}, this.allLanguageOptions)
      } else if ((type !== 'Languages') || bool) {
        this.languageOptions = [...new Set(languageOptions)]
      }
      if (this.data.dialect === 'ALL' && type === 'OralSpeechSounds') {
        this.data.dialect = ''
        // this.dialectList = Object.assign({}, this.allLanguageOptions)
      } else if ((type !== 'OralSpeechSounds') || bool) {
        this.dialectList = [...new Set(dialectList)]
      }
      // 全为空的时候 赋值(所有数据)
      if (!this.data.dialect && !this.data.language) {
        this.languageOptions = Object.assign({}, this.allLanguageOptions)
        this.dialectList = Object.assign({}, this.allDialectList)
      }
      if (bool) {
        this.rolePlayList = [...new Set(rolePlayList)]
        // // 交集 为了防止与刚开始进来的时候顺序被打乱
        // const RoleIntersect = [...new Set(this.rolePlayList.filter(x => new Set(rolePlayList).has(x)))]
        // this.rolePlayList = [...new Set([
        //   ...RoleIntersect,
        //   ...rolePlayList
        // ])]
        this.styleList = [...new Set(styleList)]
        // // 交集 为了防止与刚开始进来的时候顺序被打乱
        // const StyleIntersect = [...new Set(this.styleList.filter(x => new Set(styleList).has(x)))]
        // this.styleList = [...new Set([
        //   ...StyleIntersect,
        //   ...styleList
        // ])]
      }
      console.log(9999, queryLanguageList, this.languageOptions, this.dialectList, this.rolePlayList, this.styleList)
    },
    /**
     * 描述 结果卡片的过滤
     * @param {any} bool 角色 风格的选中 是否清空
     * @param {any} type 筛选 点击的type
     * @date 2023-09-15
     * @returns {any}
     */
    queryLanguage (type, bool) {
      if (bool) {
        // 一旦前三个里面的选项变了，其他里的选择全部清空
        this.data.role = []
        this.data.style = []
      }
      // 角色 单选
      if (this.data.role.length > 1) {
        this.data.role = [this.data.role[this.data.role.length - 1]]
      }
      // 风格 单选
      if (this.data.style.length > 1) {
        this.data.style = [this.data.style[this.data.style.length - 1]]
      }
      this.queryLanguageList = this.language.filter(item =>
        (item?.language.includes(this.data.language === 'ALL' ? '' : this.data.language)) &&
        (item?.Gender.includes(this.data.Gender)) &&
        (item?.dialect.includes(this.data.dialect === 'ALL' ? '' : this.data.dialect)) &&
        (item?.RolePlayList?.join(',').includes(this.data.role[0] || '')) &&
        (item?.StyleList?.join(',').includes(this.data.style[0] || ''))
      )
      if (bool) {
        // 筛选条件的过滤
        this.querySearch(this.queryLanguageList, type)
      }
    },
    // 选中语言卡片
    async selectLang (row) {
      this.dialogVisible = false
      this.selectLangItem = Object.assign({}, row, {
        role: this.data.role[0] || '',
        style: this.data.style[0] || '',
      })
      this.$emit('confirm', this.selectLangItem)
    },
    /**
     * 描述 点击语种 口语 关闭 其他的弹框
     * @date 2023-09-15
     * @returns {any}
     */
    focusPopover () {
      this.$refs.mergePopover.doClose()
    }
  }
}
</script>

<style lang="less" scoped>
.soundSelect {
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    /deep/.el-dialog {
      background: url("~@/assets/img/soundBg.png") no-repeat;
      background-size: 100%  100%;
      padding: 10px 10px 0px;
      .el-dialog__header {
        padding: 20px 40px 5px 20px;
      }
      .el-dialog__body {
        padding: 10px 20px 0px;
      }
      .el-dialog__footer {
        padding: 10px 20px 6px;
      }
    }
}
.soundWrap {
    height: 450px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background: #C1C1C1;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 0;
      background: #F1F1F1;
    }
}
h2 {
    font-size: 28px;
    font-weight: bold;
    color: #094391;
    line-height: 28px;
    padding-left: 28px;
}
.sexSelect {
    display: flex;
    .el-select {
        flex: 1;
        margin: 0 10px;
    }
    .el-popover__reference-wrapper {
      .el-button {
        width: 100%;
        color: #ccc;
      }
    }
}
.lanCard {
    .el-col {
        padding: 10px;
    }
    .active {
      border: 2px solid #003399;
      box-sizing: border-box;
    }
    .box-card {
        cursor: pointer;
        height: 126px;
        border-radius: 10px;
        .el-row {
            display: flex;
            align-items: center;
            .el-col {
                color: #fff;
                display: flex;
                align-items: center;
                padding: 0;
                .el-image {
                    width: 30px;
                    height: 20px;
                    margin-right: 10px;
                }
                &:nth-of-type(1) {
                    color: #333333;
                    font-size: 16px;
                    font-weight: bold;
                }
                &:nth-of-type(2) {
                    font-size: 14px;
                    color: #094391;
                    i {
                        background: #E6EEF7;
                        box-shadow: 0px 3px 9px 0px #E6EEF7;
                        border-radius: 10px;
                        padding: 2px;
                    }
                }
            }
        }
        p {
            padding: 10px 0;
        }
        .el-tag {
            margin-right: 10px;
            margin-top: 10px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            color: var(--text-active);
            background: #F3F8FF;
            border: none;
            // opacity: .8;
        }
    }
}

.popverSelect {
  max-height: 320px;
  overflow: auto;
  padding: 8px;
  p {
    padding: 4px 0;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
  }
  .el-checkbox-group {
    // max-height: 150px;
    // overflow: auto;
    .el-checkbox {
      width: 50%;
      float: left;
      margin: 0;
      padding: 7px 0;
      /* display: flex; */
      overflow: hidden;
      .el-checkbox__label {
        white-space: pre-wrap;
      }
    }
  }
}
</style>
