<template>
  <el-row class="home-wrapper">
    <el-col class="left-menu" :span="4">
      <div class="logo">
        <img src="~@/assets/logo.png" alt="">
      </div>
      <div class="item" :class="{ active: k.path === active }" v-for="(k, i) in menu" :key="i" @click="handlePath(k)">
        <i :class="[k.icon]"></i> {{ k.name }}
      </div>
      <div class='home-footer'>
        沪ICP备2023021759号
      </div>
    </el-col>
    <el-col class="right-content" :span="20">
      <div class="head">
        <switch-language
            :language="language"
            :langList="langList"
            @fnCallBack="handleSetLanguage"
          />
        <el-dropdown>
          <div class="user-info">
            <!--            <img src="~@/assets/logo.png" alt="">-->
            <i class="el-icon-s-custom"></i>
            <div>{{ userName }}</div>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="handleLogout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <router-view />
    </el-col>
  </el-row>
</template>

<script>
// @ is an alias to /src
import SwitchLanguage from '@/components/SwitchLanguage.vue'

export default {
  name: 'HomeView',
  components: {
    SwitchLanguage
  },
  data () {
    return {
      active: '',
      userName: localStorage.getItem('username') || this.$t('User'),
      menu: [
        { name: this.$t('Staging'), icon: 'el-icon-s-platform', path: '/home/workbench' },
        { name: this.$t('Myproject'), icon: 'el-icon-folder-opened', path: '/home/project' },
      ],
      menu2: [
        { name: this.$t('AccountManagement'), icon: 'el-icon-setting', path: '/home/accountManage' }
      ],
      language: localStorage.getItem('language') || 'cn',
      langList: [
        { name: '中文', value: 'cn' },
        { name: 'English', value: 'en' },
        // { name: '繁體中文', value: 'tw' },
        // { name: 'Deutsch', value: 'de' },
        // { name: 'にほんご', value: 'ja' },
        // { name: 'Italian', value: 'it' },
      ]
    }
  },
  watch: {
    '$route.path': { // $route可以用引号，也可以不用引号  监听的对象
      handler (to, from) {
        console.log('路由变化了')
        console.log('当前页面路由：' + to)
        console.log('上一个路由：' + from)
        this.active = to
      },
      deep: true, // 深度观察监听 设置为 true
      immediate: true, // 第一次初始化渲染就可以监听到
    }
  },
  mounted () {
    this.active = this.$route.path
    if (this.$store.getters.isAdmin) {
      this.menu = [
        ...this.menu,
        ...this.menu2
      ]
    }
  },
  methods: {
    handleLogout () {
      localStorage.removeItem('token')
      this.$router.push('/login')
    },
    handlePath (k) {
      this.$router.push(k.path)
      this.active = k.path
    },
    handleSetLanguage (lang) {
      this.$i18n.locale = lang
      this.setLanguage(lang)
      location.reload()
    },
    setLanguage (language) {
      this.language = language
      localStorage.setItem('language', language)
    },
  }
}
</script>
<style scoped lang="less">
.home-wrapper {
  display: flex;
  height: 100%;

  .head {
    height: 40px;
    width: 100%;
    border-bottom: 1px solid #DCE2EB;
    display: flex;
    justify-content: right;
    align-items: center;

    .user-info {
      display: flex;
      cursor: pointer;
      margin-right: 30px;
      align-items: center;
      color: var(--text-active);
      font-weight: bold;
      i {
        font-size: 25px;
        margin-right: 10px;
        color: var(--text-active);
      }

      img {
        width: 37px;
        height: 37px;
        border-radius: 50%;
        margin-right: 10px;
      }

      div {
        display: flex;
        align-items: center;
        font-size: 14px;
      }
    }
  }
  .left-menu {
    // width: 200px;
    height: 100vh;
    // border-right: 1px solid #CCC;
    padding: 20px;
    background: #F3F8FF;
    position: relative;
    .logo {
      margin: 20px 20px 80px;
    }

    .item {
      width: 100%;
      height: 60px;
      color: var(--menu-default);
      font-size: 16px;
      line-height: 36px;
      margin-bottom: 25px;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: all 0.3s;
      overflow: hidden;
      i {
        font-size: 18px;
        margin-right: 10px;
        margin-left: 20px;
      }

      &:hover {
        color: #0C4ECD;
        background-color: #FFFFFF;
        border-radius: 4px;
        font-weight: 600;
      }
    }

    .active {
      color: #094391;
      background-color: #FFFFFF;
      border-radius: 4px;
      font-weight: 600;
      position: relative;
      &:before {
        position: absolute;
        display: block;
        content: '';
        width: 6px;
        height: 60px;
        background: #094391;
      }
    }
    .home-footer {
      width: 100%;
      height: 36px;
      background: rgba(245, 245, 245, 0.39);
      opacity: 1;
      text-align: center;
      line-height: 36px;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  .right-content {
    // flex: 1;
  }
}
</style>
