export default {
  data () {
    return {
      sort: {
        orderby: 'createon',
        sort: 'desc',
      },
    }
  },
  methods: {
    // 排序
    elHandleSort (data) {
      if (data.order) {
        this.sort.orderby = data.prop
        this.sort.sort = data.order === 'descending' ? 'desc' : 'asc'
      } else {
        this.sort = {}
      }
      this.getList()
    },
    singleSearch () {
      this.pages.page = 1
      this.getList()
    },
    handleSizeChange (item) {
      // 一页最大显示数量
      this.pages.page = 1
      this.pages.size = item
      this.getList()
    },
    handleCurrentChange (item) {
      this.pages.page = item
      this.getList()
    },
    resetSearch () {
      this.pages.page = 1
      this.searchForm = {}
      this.getList()
    },
  },
}
