<template>
  <div class="AccountManage">
      <div class="AccountManageTitle">
          <h2>{{ (companyData.company && companyData.company.name) ? companyData.company.name : '' }}</h2>
          <p v-if='time'>
              {{ $t('RemainingAvailableTime') }}: {{ time }}
              <span v-show="endTime && durationTime"> ({{ $t('Wherein') }} {{ durationTime }}, {{ $t('WillBeOn') }}{{ endTime }}{{ $t('Expire') }})</span>
          </p>
          <p v-else class="text-red">
            {{ $t('ThereIsCurrentlyNoAvailableDuration') }}
          </p>
      </div>
      <div class="AccountManageList">
        <el-row class="searchWarp">
            <el-col>
                <el-button type="primary" @click="addAccount()">{{ $t('AddAccount') }}</el-button>
            </el-col>
        </el-row>
        <el-table
            :data="tableList"
            class="main_tab"
            stripe
            border
            ref="expandTableTop"
            >
            <el-table-column type="index" :label="$t('SerialNumber')" align="center" width="110" />
            <el-table-column
                prop="username"
                :label="$t('AccountNumber')"
                align="center"
            />
            <el-table-column
                prop="password"
                :label="$t('Password')"
                align="center"
            />
            <el-table-column :label="$t('Operate')" align="center">
                <template slot-scope="scope">
                <el-button type="text" @click="handleEdit(scope.row)">{{ $t('ModifyAccountInfo') }}</el-button>
                <!-- <el-button type="text" @click="handleDel(scope.row)">{{ $t('del') }}</el-button> -->
                </template>
            </el-table-column>
        </el-table>
      </div>
      <el-pagination
            :current-page="pages.page"
            :page-size="pages.size"
            :total="pages.total"
            background
            layout="prev, pager, next, jumper, total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        />
    <editUserDialog v-model='userDialogVisible' :item="item" :type='type' :companyId="companyId" @confirm='handleConfirm'/>
  </div>
</template>

<script>
import editUserDialog from './components/editUserDialog.vue'
import paginations from '@/mixins/pagination_mixin'
import { getFormateDate } from '@/utils/common'
export default {
  name: 'AccountManage',
  data () {
    return {
      userDialogVisible: false,
      type: '',
      companyId: '',
      companyData: [],
      item: {
        id: '',
        username: '',
        password: ''
      },
      pages: {
        size: 10,
        page: 1,
        total: 0,
      },
      tableList: [],
      time: '0',
      durationTime: '',
      endTime: ''
    }
  },
  components: {
    editUserDialog
  },
  // getCompanyInfo () {
  //   return this.$store.getters.companyInfo || {}
  // },
  mixins: [paginations],
  mounted () {
    this.getCompany()
  },
  methods: {
    async getCompany () {
      try {
        const res = await this.$api.getCompany()
        this.companyId = res.data[0].id || ''
        this.getList()
        try {
          const res1 = await this.$api.getCompanyDetail(this.companyId)
          this.companyData = res1.data
          this.getTime(this.companyData)
        } catch (error) {
          console.log(error)
        }
      } catch (error) {
        console.log(error)
      }
      // this.$store.dispatch('getCompanyInfo')
      // this.companyData = this.$store.getters.companyInfo || []
    },
    async getList () {
      try {
        const res = await this.$api.getUsers({
          name: '',
          companyId: this.companyId,
          page: this.pages.page,
          size: this.pages.size
        })
        this.tableList = res.data
        this.pages.total = Number(res.count)
      } catch (error) {
        console.log(error)
      }
    },
    getTime (data) {
      // const timeData = data.goodsList.filter(e =>
      //   new Date(e.end).getTime() - new Date().getTime() > 0
      // )
      const timeData = data.goodsList.filter(e =>
        e.end
      )
      let t = 0
      data.goodsList.forEach(e => {
        t = t + Number(e.duration)
      })
      this.time = this.getCompleteTime(t)
      if (timeData.length > 0) {
        // this.time = this.getCompleteTime(new Date().getTime(), new Date(timeData[0].end).getTime())
        this.durationTime = this.getCompleteTime(timeData[0].duration)
        this.endTime = getFormateDate(timeData[0].end, 'yyyy/MM/dd') || ''
      } else {
        this.endTime = ''
      }
    },
    // getCompleteTime (faultDate, completeTime) {
    //   const stime = Date.parse(new Date(faultDate))
    //   const etime = Date.parse(new Date(completeTime))
    //   // 两个时间戳相差的毫秒数
    //   const usedTime = etime - stime
    //   // 计算相差的天数
    //   const days = Math.floor(usedTime / (24 * 3600 * 1000))
    //   // 计算天数后剩余的毫秒数
    //   const leave1 = usedTime % (24 * 3600 * 1000)
    //   // 计算出小时数
    //   const hours = Math.floor(leave1 / (3600 * 1000))
    //   // 计算小时数后剩余的毫秒数
    //   const leave2 = leave1 % (3600 * 1000)
    //   // 计算相差分钟数
    //   const minutes = Math.floor(leave2 / (60 * 1000))
    //   // 计算相差秒数
    //   const seconds = (leave2 - minutes * (60 * 1000)) / 1000
    //   const time = (days * 24 + hours) + '时' + minutes + '分' + seconds + '秒'
    //   return {
    //     time,
    //     days
    //   }
    // },
    getCompleteTime (times) {
      // 计算出小时数
      const hours = Math.floor(times / 3600)
      // 计算小时数后剩余的秒数
      const leave2 = times % 3600
      // 计算相差分钟数
      const minutes = Math.floor(leave2 / 60)
      // 计算相差秒数
      const seconds = leave2 % 60
      // const time = hours + '时' + minutes + '分' + seconds + '秒'
      const time = (hours ? (hours + '时') : '') + (minutes ? (minutes + '分') : '') + (seconds ? (seconds + '秒') : '')
      return time
    },
    addAccount () {
      this.userDialogVisible = true
      this.type = 'add'
    },
    handleEdit (row) {
      this.userDialogVisible = true
      this.type = 'edit'
      this.item = row
    },
    handleDel (row) {
      this.$confirm(this.$t('HintText.areYouSureDelete'), this.$t('HintText.hint'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(async () => {
        try {
          await this.$api.deleteUser(row.id)
          this.$message({
            type: 'success',
            message: this.$t('HintText.successfullyDelete')
          })
        } catch (error) {
          this.$message({
            type: 'warning',
            message: this.$t('HintText.failToDelete')
          })
        }
        this.getList()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('HintText.Undeleted')
        })
      })
    },
    handleConfirm (data) {
      this.getList()
    }
  }
}
</script>

<style scoped lang="less">
.AccountManage {
  display: flex;
  flex-direction: column;
  height: calc(100% - 107px);
  background: url("~@/assets/img/soundBg.png") no-repeat;
  background-size: 100%  100%;
  padding: 30px;
  overflow-y: auto;
  &Title {
    h2 {
        font-size: 28px;
        font-weight: bold;
        color: #094391;
        line-height: 40px
    }
    p {
        color: #000;
        font-size: 14px;
        line-height: 24px;
    }
    .text-red {
      color: red;
    }
  }
  &List {
    color: #000;
    min-height: 480px;
    .searchWarp {
        .el-col {
            display: flex;
            justify-content: end;
            padding-bottom: 20px;
        }
    }
  }
  // .el-table {
  //   min-height: 300px;
  // }
}
</style>
