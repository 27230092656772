<template>
  <div class="ProjectWrapper">
    <!-- <div class="item" v-for="(v) in projectList" :key="v.id">
      <VideoItem :video-data="v" @showDialog="handleShow" @deleteVideo="handleDel"/>
    </div> -->
    <el-tabs v-model="activeName">
      <el-tab-pane :label="$t('MyProject')" name="1">
        <el-row>
          <el-col :span='6' v-for="(v, index) in projectList" :key="index" class="cardItem">
            <el-card shadow="hover">
              <VideoItem :video-data="v" type="project" @showDialog="handleShow" @deleteVideo="handleDel"/>
            </el-card>
          </el-col>
        </el-row>
        <el-pagination
            :current-page="pages.page"
            :page-size="pages.size"
            :total="pages.total"
            background
            layout="prev, pager, next, jumper, total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
      </el-tab-pane>
      <el-tab-pane :label="$t('MyDraft')" name="2">
        <el-row>
          <el-col :span='6' v-for="(v, index) in draftList" :key="index" class="cardItem">
            <el-card shadow="hover">
              <VideoItem :video-data="v" type="draft" @showDialog="handleShow" @deleteVideo="handleDel"/>
            </el-card>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
    <FaceSwapDialog v-model="dialogVisible" :videoInfo="videoInfo" @confirm="confirmFaceSwap" />
  </div>
</template>

<script>
import VideoItem from '@/components/VideoItem.vue'
import FaceSwapDialog from '@/components/FaceSwapDialog.vue'
export default {
  name: 'ProjectWrapper',
  inject: ['hideGLoading', 'showGLoading'], // 全局loading
  data () {
    return {
      projectList: [],
      draftList: [],
      dialogVisible: false,
      imgUrl: '',
      file: '',
      loading: false,
      videoUrl: '',
      activeName: '1',
      pages: {
        size: 12,
        page: 1,
        total: 0,
      },
      videoInfo: {}
    }
  },
  components: {
    VideoItem,
    FaceSwapDialog
  },
  mounted () {
    this.handleInit()
    this.handleDraftInit()
  },
  methods: {
    handleFile (file) {
      this.file = file.target.files[0]
      this.imgUrl = URL.createObjectURL(file.target.files[0])
    },
    async handleUpload () {
      if (!this.file) return
      const formData = new FormData()
      formData.append('file', this.file)
      this.loading = true
      try {
        await this.$api.uploadTalkingPhoto(formData)
        this.$message({
          message: '上传成功',
          type: 'success'
        })
        this.loading = false
        this.handleClear()
        await this.handlePhotos()
        this.$forceUpdate()
      } catch (error) {
        this.loading = false
        this.$message({
          message: error.response.data.msg || this.$t('HintText.uploadFailed'),
          type: 'error'
        })
      }
    },
    handleClear () {
      if (this.loading) return
      this.imgUrl = ''
      this.file = null
      this.$refs.file.value = ''
    },
    handleUp () {
      if (this.imgUrl) return
      this.$refs.file.click()
    },
    handleDel (video) {
      this.$confirm('删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: async (action, ctx, close) => {
          if (action !== 'confirm') {
            close()
            return
          }
          ctx.confirmButtonLoading = true
          try {
            await this.$api.delVideos({
              videoId: video.id
            })
            ctx.confirmButtonLoading = false
            this.projectList = this.projectList.filter(v => v.id !== video.id)
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            close()
          } catch (error) {
            ctx.confirmButtonLoading = false
            this.$message({
              type: 'error',
              message: '删除失败!'
            })
            close()
            console.log(error)
          }
        }
      }).catch(() => {})
    },
    async handleShow (v) {
      console.log('vvv', v)
      if (!v.asset_id) {
        this.showGLoading()
        this.$message(this.$t('HintText.CheckingVideoResourcesPleaseWait'))
        try {
          await this.$api.uploadVideo({
            id: v.id
          })
          // 这里后端没返回数据 就去掉了判断
          this.hideGLoading()
          this.videoInfo = {}
          this.videoInfo = Object.assign({}, v)
          this.dialogVisible = true
          this.handleInit()
        } catch (error) {
          this.hideGLoading()
        }
      } else {
        this.videoInfo = {}
        this.videoInfo = Object.assign({}, v)
        this.dialogVisible = true
      }
    },
    handleBeforeClose (done) {
      this.videoUrl = ''
      this.handleClear()
      done()
    },
    handleClose () {
      this.dialogVisible = false
      this.videoUrl = ''
      this.handleClear()
    },
    // 项目分页
    handleSizeChange (item) {
      // 一页最大显示数量
      this.pages.page = 1
      this.pages.size = item
      this.handleInit()
    },
    handleCurrentChange (item) {
      this.pages.page = item
      this.handleInit()
    },
    confirmFaceSwap (data) {
      this.handleInit()
    },
    async handleInit () {
      try {
        const res = await this.$api.centerAvatars({
          asc: false,
          page: this.pages.page,
          size: this.pages.size
        })
        this.projectList = res.data || []
        this.pages.total = Number(res.count)
        this.$forceUpdate()
      } catch (error) {
        console.log(error)
      }
    },
    async handleDraftInit () {
      try {
        const res = await this.$api.projectsDraft({
          // sort: 'dateCreated',
          asc: false,
          page: 1,
          size: 1000
        })
        this.draftList = res.data || []
        this.draftList.forEach(e => {
          e.url = e.audio
        })
        this.draftList = this.sortBy(this.draftList)
      } catch (error) {
        console.log(error)
      }
    },
    // 对日期进行 倒序  排序（后端没支持排序）
    sortBy (par) {
      const arr = [
        ...par
      ]
      return arr.sort(function (a, b) {
        return new Date(a.dateCreated).getTime() < new Date(b.dateCreated).getTime() ? 1 : -1
      })
    }
  }
}
</script>

<style scoped lang="less">
.huanlian {
  display: flex;
  align-items: center;
  video {
    height: 175px;
  }
  .up {
    margin-left: 20px;
    height: 175px;
    width: 175px;
    border: 1px dashed #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    i {
      font-size: 25px;
      //color: #FFF;
    }
    .el-icon-delete {
      color: #FFF;
    }
    .mask {
      display: none;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      align-items: center;
      justify-content: center;
      background: rgba(0, 0, 0, 0.5);
    }
    &:hover {
      .mask {
        display: flex;
      }
    }
  }
}
.ProjectWrapper {
  height: calc(100% - 110px);
  overflow: auto;
  padding: 15px 20px 20px;
/deep/.el-tabs {
    .el-tabs__header {
      padding: 0 20px;
      margin: 0 0 10px;
    }
    .el-tabs__nav-wrap {
      &::after {
        display: none;
      }
      .el-tabs__active-bar {
        display: none;
      }
      .el-tabs__item {
        font-weight: bold;
        opacity: .5;
        color: #094391;
        font-size: 18px;
      }
      .el-tabs__item.is-active {
        opacity: 1;
      }
    }
  }
  .cardItem {
    padding: 10px;
    .el-card {
      cursor: pointer;
      border: none;
      border-radius: 10px;
      /deep/.el-card__body {
        padding: 21px;
        min-height: 299px;
      }
    }
  }
}
</style>
