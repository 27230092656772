import { render, staticRenderFns } from "./VideoItemDialog.vue?vue&type=template&id=093ba520&scoped=true"
import script from "./VideoItemDialog.vue?vue&type=script&lang=js"
export * from "./VideoItemDialog.vue?vue&type=script&lang=js"
import style0 from "./VideoItemDialog.vue?vue&type=style&index=0&id=093ba520&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "093ba520",
  null
  
)

export default component.exports