import Vue from 'vue'
import Vuex from 'vuex'
import { Base64 } from '@/utils/common'

Vue.use(Vuex)

// const jwt = require('jsonwebtoken')

export default new Vuex.Store({
  state: {
    voiceList: [
      // 'https://admax-1318679842.cos.ap-singapore.myqcloud.com/digital/20230630/9824d88d0cd3444c8f9403daa28137ec.mp3',
    ],
    videoItem: {},
    companyInfo: {},
    isAdmin: {}
  },
  getters: {
    videoItem: state => state.videoItem,
    companyInfo: state => state.companyInfo,
    isAdmin: state => state.isAdmin,
  },
  mutations: {
    changeVoice (state, data) {
      state.voiceList.unshift({
        url: data,
        name: ` 语音文件${state.voiceList.length + 1}`
      })
    },
    setVideo: (state, videoItem) => {
      state.videoItem = videoItem
    },
    setCompanyInfo: (state, companyInfo) => {
      state.companyInfo = companyInfo
    },
    setAdmin: (state, isAdmin) => {
      // 解密
      // const token = isAdmin
      // console.log('888888', jwt.verify(token))
      // const secretKey = 'role'
      // try {
      //   // 两个参数
      //   // 第一个参数: 前端传递过来的 token
      //   // 第二个参数: 设置的私钥
      //   const decoded = jwt.verify(token, secretKey)
      //   // 令牌验证成功，`decoded`将包含解码后的令牌数据
      //   console.log('decoded', decoded)
      // } catch (error) {
      //   // 令牌验证失败
      //   console.error('Token verification failed:', error)
      // }
      console.log('isAdmin', isAdmin)
      console.log('JWTInfo2', Base64.decode(isAdmin))
      state.isAdmin = Base64.decode(isAdmin).includes('ROLE_COMPANY')
      console.log('state.isAdmin', state.isAdmin)
    },
  },
  actions: {
    async getCompanyInfo ({ commit }) {
      const companyInfo = {}
      try {
        const res = await this.$api.getCompany()
        const companyData = res
        try {
          const res1 = await this.$api.getCompanyDetail(companyData[0].id)
          this.companyInfo = res1
        } catch (error) {
          console.log(error)
        }
      } catch (error) {
        console.log(error)
      }
      return new Promise((resolve, reject) => {
        commit('setCompanyInfo', companyInfo)
        resolve()
      })
    },
  },
  modules: {
  }
})
