<template>
  <el-dialog
    :title="$t('AccountPassword')"
    class="video"
    :show-close="false"
    :visible.sync="dialogVisible"
    center
    width="500px"
  >
    <div slot="title"></div>
    <el-form
      :model="ruleForm"
      status-icon
      :rules="rules"
      ref="ruleForm"
      label-width="130px"
      class="demo-ruleForm"
    >
      <el-form-item :label="$t('OriginalAccount')" v-if="type === 'edit' && data.username">
        <el-input
          v-model="data.username"
          autocomplete="off"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('OriginalPassword')" v-if="type === 'edit' && data.password">
        <el-input
          v-model="data.password"
          autocomplete="off"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('NewAccount')" prop="username" v-show="type === 'add'">
        <el-input
          v-model="ruleForm.username"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('NewPassword')" prop="password">
        <el-input
          v-model="ruleForm.password"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >{{ $t('confirm') }}</el-button
        >
        <el-button @click="dialogVisible = false">{{ $t('close') }}</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  name: 'editUserDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    },
    companyId: {
      type: String,
      default: ''
    },
    item: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    // const validateName = (rule, value, callback) => {
    //   if (value === '') {
    //     callback(new Error('请输入账号'))
    //   } else {
    //     if (this.ruleForm.username !== '') {
    //       this.$refs.ruleForm.validateField('username')
    //     }
    //     callback()
    //   }
    // }
    // const validatePass = (rule, value, callback) => {
    //   if (value === '') {
    //     callback(new Error('请输入密码'))
    //   } else {
    //     if (this.ruleForm.password !== '') {
    //       this.$refs.ruleForm.validateField('password')
    //     }
    //     callback()
    //   }
    // }
    // const validatePass2 = (rule, value, callback) => {
    //   if (value === '') {
    //     callback(new Error('请再次输入密码'))
    //   } else if (value !== this.ruleForm.username) {
    //     callback(new Error('两次输入密码不一致!'))
    //   } else {
    //     callback()
    //   }
    // }
    return {
      dialogVisible: false,
      data: {
        username: '',
        password: '',
      },
      ruleForm: {
        username: '',
        password: '',
      },
      rules: {
        // username: [{ validator: validateName, trigger: 'blur' }],
        // password: [{ validator: validatePass, trigger: 'blur' }],
        username: [{ required: true, message: this.$t('HintText.PleaseEnterAccount'), trigger: 'blur' }],
        password: [{ required: true, message: this.$t('HintText.PleaseEnterPassword'), trigger: 'blur' }],
      }
    }
  },
  watch: {
    value (val) {
      this.dialogVisible = val
      if (val) {
        this.ruleForm = {
          username: '',
          password: '',
        }
      }
    },
    dialogVisible (val) {
      this.$emit('input', val)
    },
    type (val) {
      if (val === 'edit') {
        this.data = this.item
        this.rules.username[0].required = false
      } else {
        this.rules.username[0].required = true
      }
    },
    item: {
      handler: function (val) {
        this.data = this.item
      },
      deep: true,
      immediate: true
    }
  },
  async mounted () {
    // await Promise.all([this.handlePhotos(), this.handleAvatar()])
  },
  methods: {
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.dialogVisible = false
          if (this.type === 'add') {
            try {
              await this.$api.addUsers(Object.assign({}, this.ruleForm, {
                companyId: this.companyId
              }))
              this.$message.success(this.$t('HintText.SuccessfullyAddedAccount'))
              this.$emit('confirm', this.item)
            } catch (error) {
              this.$message.error(error.response.data.msg || this.$t('HintText.FailedAddedAccount'))
              console.log(error)
            }
          } else {
            try {
              await this.$api.resetPassword({
                id: this.item.id,
                password: this.ruleForm.password
              })
              this.$message.success(this.$t('HintText.SuccessfullyModifiedAccount'))
              this.$emit('confirm', this.item)
            } catch (error) {
              this.$message.error(error.response.data.msg || this.$t('HintText.FailedModifiedAccount'))
              console.log(error)
            }
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
/deep/.el-dialog {
  background: url('~@/assets/img/soundBg.png') no-repeat;
  background-size: 100% 100%;
  padding: 10px 10px 0px;
}
</style>
