<template>
  <el-dialog :title="$t('Translate')" class="AI"
    :show-close='false'
    :visible.sync="dialogVisible"
    width="42%">
    <div slot="title">
    </div>
    <h2>
      {{ $t('AIIntellCopywritingGeneration') }}
    </h2>
    <el-row>
      <el-col>
        <el-input
          type="textarea"
          :placeholder="$t('PlaceholderText.pleaseEnterTheContent')"
          v-model="data.content"
          maxlength="1000"
          show-word-limit
        >
        </el-input>
      </el-col>
    </el-row>
    <el-row>
        <el-button type="primary" :disabled="loading"
            :loading="loading" @click="handleGPT()">
            {{ $t('GenerateCopy') }}
        </el-button>
    </el-row>
    <div v-if="data.TranlateText.length > 0" style="max-height: 300px;
      overflow: auto;">
      <el-row class="translateCon" v-for="(item, index) in data.TranlateText" :key="index">
          <el-col>
            <p> {{ item }}</p>
            <p>
              <el-button type="primary"
              class="UsingCopyBtn"
              @click="submit(item)"
              >
                {{ $t('UsingCopy') }}
              </el-button>
            </p>
          </el-col>
      </el-row>
    </div>
    <!-- <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit()">引用文本</el-button>
    </span> -->
  </el-dialog>
</template>

<script>

export default {
  name: 'AIDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    // speechData: {
    //   type: Object,
    //   default: () => {
    //     return {}
    //   }
    // },
  },
  data () {
    return {
      dialogVisible: false,
      data: {
        content: '',
        TranlateText: [],
      },
      loading: false,
    }
  },
  watch: {
    value (val) {
      this.dialogVisible = val
      if (val) {
        console.log(val)
        this.data = {
          content: '',
          TranlateText: [],
        }
        this.loading = false
        // this.data = Object.assign({}, this.speechData)
      }
    },
    dialogVisible (val) {
      this.$emit('input', val)
    }
  },
  computed: {
    host () {
      return process.env.NODE_ENV === 'production' ? '/digital' : 'http://144.34.180.209/digital/'
    },
    action () {
      return `${this.host}api/v1/materials/uploadTalkingPhoto`
    }
  },
  async mounted () {
    // await Promise.all([this.handlePhotos(), this.handleAvatar()])
  },
  methods: {
    async handleGPT () {
      if (!this.data.content) {
        return this.$message.error(this.$t('HintText.pleaseEnterTheCopy'))
      }
      try {
        if (this.loading) return
        this.loading = true
        const res = await this.$api.copyWriting({
          content: this.data.content,
          // language: this.data.name
        })
        this.loading = false
        this.data.TranlateText.push(res.data)
      } catch (error) {
        this.$message.error(error.response.data.msg || this.$t('HintText.GenerationError'))
        this.loading = false
      }
    },
    submit (data) {
      this.dialogVisible = false
      this.$emit('confirm', data)
    }
  }
}
</script>

<style scoped lang="less">
/deep/.el-dialog {
  background: url("~@/assets/img/soundBg.png") no-repeat;
  background-size: 100%  100%;
  padding: 10px 10px 0px;
  .el-dialog__header {
    padding: 6px;
  }
}
/deep/.el-dialog__body {
  padding: 30px 60px 50px;
  // max-height: 500px;
  // overflow: auto;
  h2 {
    height: 26px;
    font-size: 26px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #094391;
    line-height: 26px;
    text-align: center;
  }
  .el-row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    .el-col {
      .el-textarea__inner {
        height: 200px!important;
        max-height: 200px!important;
      }
      font-size: 20px;
    }
  }
  .translateCon {
    // max-height: 200px;
    // overflow: auto;
    .el-col {
      display: flex;
      align-items: center;
      padding: 20px 0 20px 20px;
      background: #F7F8FA;
      border-radius: 10px;
      p {
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 32px;
      }
      p:nth-of-type(1) {
        flex: 1;
      }
      p:nth-of-type(2) {
        width: 120px;
        text-align: center;
      }
    }
  }
  .el-button {
    background: #729CD5;
    font-weight: bold;
  }
  .UsingCopyBtn {
    background: transparent;
    border: none;
    font-weight: bold;
    color: #094391;
    opacity: .5;
  }
  .el-textarea__inner {
    border-radius: 10px;
    border: none;
    resize: none;
    box-shadow: 0px 3px 9px 0px #E6EEF7;
  }
}
</style>
