<template>
  <el-dropdown class="international" @command="handleSetLanguage">
    <span class="el-dropdown-link">
      <i class="iconfont icon-fanyi" style="vertical-align: middle"></i>
      {{ langName || language }}
      <i class="el-icon-arrow-down" />
    </span>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item
        v-for="item in langList"
        :key="item.value"
        :disabled="language === item.value"
        :command="item.value"
        style="font-size: 12px"
        >{{ item.name }}</el-dropdown-item
      >
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  name: 'SwitchLanguage',
  data () {
    return {
      langName: ''
    }
  },
  props: {
    language: {
      type: String,
      default: ''
    },
    langList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  watch: {
    language: {
      handler (val) {
        this.langList.forEach(item => {
          if (val === item.value) {
            this.langName = item.name
          }
        })
      },
      immediate: true
    }
  },
  methods: {
    handleSetLanguage (lang) {
      this.$emit('fnCallBack', lang)
    }
  }
}
</script>

<style lang="less" scoped>
.international {
  cursor: pointer;
  margin-right: 20px;
  .icon {
    font-size: 20px;
    color: #091452;
    font-weight: bold;
    cursor: pointer;
  }
}
/deep/ .el-dropdown-selfdefine {
  font-size: 13px;
}
/deep/ .el-dropdown-link {
  font-size: 13px;
}
</style>
