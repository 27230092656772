<template>
  <el-dialog
    :title="$t('FaceTransplant')"
    class="faceSwap"
    :show-close="false"
    :visible.sync="dialogVisible"
    :before-close="handleBeforeClose"
    center
    width="960px"
  >
    <div slot="title"></div>
    <el-row>
      <el-col :span="11">
        <el-card shadow="hover">
          <VideoItem :video-data="videoInfo" type="view" />
        </el-card>
      </el-col>
      <el-col :span="11" :offset="2">
        <div>
          <el-card shadow="hover">
            <el-input v-model="form.name" :placeholder="$t('PlaceholderText.pleaseEnterTitle')"></el-input>
          </el-card>
        </div>
        <div class="item">
          <el-card shadow="hover">
            <el-image fit='contain' :src='imgUrl' v-show="imgUrl" @click="imgClick()" />
            <el-upload
                      v-show="!imgUrl"
                      ref="upload"
                      class="upload-demo avatar-uploader"
                      action
                      :http-request="handleUpload"
                      :on-change="handleUploadForm"
                      :headers="headers"
                      :before-upload="beforeAvatarUpload"
                      :loading='loading'
                      :show-file-list="false"
                      :auto-upload="false"
                      >
               <i class="el-icon-plus avatar-uploader-icon" style="font-size: 40px;"></i>
               <!-- <img
                    v-if="file.url"
                    class="el-upload-list__item-thumbnail"
                    :src="file.url" alt=""
                > -->
            </el-upload>
          </el-card>
        </div>
      </el-col>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <el-button
        type="primary"
        :disabled="loading"
        :loading="loading"
        @click="submit()"
      >
        {{ $t('FaceTransplant') }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import $ from 'jquery'
import VideoItem from '@/components/VideoItem.vue'
export default {
  name: 'FaceSwapDialog',
  components: {
    VideoItem
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    videoInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      dialogVisible: false,
      data: {},
      textarea: '',
      loading: false,
      file: {},
      form: {
        name: ''
      },
      step: 1,
      imgUrl: ''
    }
  },
  watch: {
    value (val) {
      this.dialogVisible = val
      if (val) {
        console.log(val)
        // this.data = Object.assign({}, this.videoInfo)
        this.$forceUpdate()
      }
      this.loading = false
      this.imgUrl = ''
    },
    dialogVisible (val) {
      this.$emit('input', val)
    },
    videoInfo: {
      handler: function (val) {
        console.log('videoInfo', val.url)
        this.$nextTick(() => {
          this.data = Object.assign({}, val)
          this.$forceUpdate()
        })
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    host () {
      return process.env.NODE_ENV === 'production'
        ? '/digital'
        : 'http://144.34.180.209/digital/'
    },
    action () {
      return `${this.host}api/v1/materials/uploadTalkingPhoto`
    },
    headers () {
      const token = localStorage.getItem('token')
      return { Authorization: `Bearer ${token}` }
    }
  },
  async mounted () {
    // await Promise.all([this.handlePhotos(), this.handleAvatar()])
  },
  methods: {
    handleBeforeClose (done) {
      this.data.url = ''
      // this.$set(this.data, 'url', '')
      done()
    },
    imgClick () {
      console.log('imgClick')
      $('.upload-demo .el-upload').click()
    },
    beforeAvatarUpload (file) {
      console.log('file', file)
    },
    handleRemove (file, fileList) {
      console.log(file, fileList)
    },
    handlePreview (file) {
      console.log(file)
      this.file = file
    },
    async handleUploadForm (file, fileList) {
      console.log('handleUploadForm', file, fileList)
      this.file = file
      this.loadImg(file.raw)
    },
    loadImg (file) {
      const that = this
      const oFReader = new FileReader()
      oFReader.readAsDataURL(file)
      oFReader.onloadend = function (oFRevent) {
        // base64/png
        that.imgUrl = oFRevent.target.result || ''
        // console.log('this.imgUrl', that.imgUrl)
      }
    },
    async handleUpload () {
      console.log('handleUpload', this.file)
      try {
        if (this.loading) return
        this.loading = true
        await this.$api.getFaceSwapReady({
          id: this.data.id,
          url: this.data.url,
          step: this.step
        })
        // if (!!readyRes.data.status && readyRes.data.status === '3') {
        //   this.$message({
        //     message: this.$t('HintText.PleaseContactAdmaxPersonnel'),
        //     type: 'error'
        //   })
        //   return
        // }
        const formData = new FormData()
        // 额外参数
        formData.append('id', this.data.id)
        formData.append('file', this.file.raw)
        try {
          const res = await this.$api.uploadAndSwap(formData)
          // this.$message({
          //   message: '上传成功',
          //   type: 'success'
          // })
          // try {
          //   await this.$api.getFaceswap({
          //     assetId: res.data,
          //     name: this.form.name,
          //   })
          //   this.dialogVisible = false
          //   this.$emit('confirm', '')
          // } catch (error) {
          //   this.$message({
          //     message: error.msg,
          //     type: 'error'
          //   })
          // }
          this.$message(this.$t('HintText.TheCurrentTaskHasBeenAddedToQueueForProcessingPleaseBePatientAndPatient'))
          const timer = setInterval(async () => {
            try {
              const res1 = await this.handleLunxun(res || '')
              // const res1 = resLunxun.data
              if (res1.job_status === '3') {
                this.$message.error(this.$t('HintText.TheCurrentTaskBusyPleaseWaitBeforeTryingAgain'))
                this.loading = false
              }
              if (res1.job_status === '3' || res1.job_status === '2') {
                clearInterval(timer)
                this.loading = false
              }
              if (res1.job_status === '2') {
                this.$message.success(this.$t('HintText.VideoFacelifteSuccessful'))
                this.dialogVisible = false
                this.$emit('confirm', '')
              }
            } catch (error) {
              // 提示的暂时不取后端msg（需求让改的）
              this.$message.error(this.$t('HintText.TheCurrentTaskBusyPleaseWaitBeforeTryingAgain'))
              clearInterval(timer)
              this.loading = false
            }
          }, 6000)
        } catch (error) {
          this.loading = false
          // 后端没有msg 前端给提示
          this.$message({
            message: error.msg || error.response.data.msg || this.$t('HintText.uploadFailed'),
            type: 'error'
          })
        }
      } catch (error) {
        this.loading = false
        // 失败的时候step ++
        this.step = error.response.data.msg
        this.$message({
          message: this.$t('HintText.TheCurrentTaskBusyPleaseWaitBeforeTryingAgain'),
          type: 'error'
        })
      }
    },
    async handleLunxun (v) {
      try {
        const res = await this.$api.getFaceswap({
          assetId: v.data,
          name: this.form.name,
          background: this.videoInfo.background || '#FFFFFF'
        })
        return res.data || ''
      } catch (e) {
        console.log(e)
      }
    },
    submit () {
      console.log('submit')
      this.handleUpload()
    }
  }
}
</script>

<style scoped lang="less">
/deep/.el-dialog {
  background: url('~@/assets/img/soundBg.png') no-repeat;
  background-size: 100% 100%;
  padding: 10px 10px 0px;
  .el-dialog__body {
    padding: 25px 50px 30px;
  }
}
.el-row {
  display: flex;
  align-items: center;
  // text-align: center;
  .video {
    height: 200px;
  }
  .el-col {
    font-size: 20px;
  }
}
/deep/.el-dialog__footer {
  padding: 0px 20px 30px;
  .dialog-footer {
    .el-button {
      font-size: 16px;
      font-weight: bold;
      background: #094391;
      height: 42px;
      padding: 0 40px;
    }
  }
}
.item {
  margin-top: 20px;
  /deep/.el-card__body {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .upload-demo {
    line-height: 150px;
    text-align: center;
  }
  .el-image {
    height: 150px;
    cursor: pointer;
  }
}
</style>
