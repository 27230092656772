// 中文
module.exports = {
  SaveDraft: '保存草稿',
  CompositeVideo: '合成视频',
  AIGeneratedVoice: 'AI生成语音',
  ManuallyUploadVoice: '手动上传语音',
  SpeechSpeed: '语速',
  ClearContent: '清空内容',
  SyntheticAudio: '合成音频',
  ChooseDigitalPerson: '选择数字人',
  All: '所有',
  More: '更 多',
  ChooseAccessories: '选择配饰',
  UploadPhotos: '上传照片',
  SoundSquare: '声音广场',
  Sex: '性 别',
  Languages: '语 种',
  OralSpeechSounds: '口 音',
  Role: '角 色',
  Style: '风 格',
  Other: '其 他',
  Male: '男',
  Female: '女',
  Translate: '翻译',
  TranslationLanguage: '翻译语言',
  AIIntellCopywritingGeneration: 'AI智能文案生成',
  GenerateCopy: '生成文案',
  UsingCopy: '使用文案',
  YourBrowserDoesNotSupportAudioPlayback: '您的浏览器不支持音频播放',
  FaceTransplant: '换 脸',
  QuoteText: '引用文本',
  Down: '下 载',
  Edit: '编 辑',
  MyProject: '我的项目',
  MyDraft: '我的草稿',
  RemainingAvailableTime: '剩余可用时长',
  Wherein: '其中',
  WillBeOn: '将于',
  Expire: '过期',
  AddAccount: '新增账号',
  SerialNumber: '序号',
  AccountNumber: '账号',
  Password: '密码',
  Operate: '操作',
  ModifyAccountInfo: '修改账号信息',
  del: '删除',
  close: '关 闭',
  confirm: '确 定',
  cancel: '取 消',
  AccountPassword: '账号密码',
  OriginalAccount: '原账号',
  OriginalPassword: '原密码',
  NewAccount: '新账号',
  NewPassword: '新密码',
  User: '用户',
  Staging: '工作台',
  Myproject: '我的项目',
  AccountManagement: '账号管理',
  Hour: '时',
  Minute: '分',
  second: '秒',
  ThereIsCurrentlyNoAvailableDuration: '暂无可用时长 (套餐已过期）',
  PlaceholderText: {
    pleaseEnterTheContent: '请输入内容',
    pleaseEnterTitle: '请输入标题',
  },
  HintText: {
    hint: '提示',
    pleaseEnterVideoTitle: '请输入视频标题',
    saveSuccessfully: '保存成功',
    failToSave: '保存失败',
    pleaseSelectLanguage: '请选择语言',
    pleaseEnterTheContent: '请输入内容',
    SuccessfullyGenerated: '生成成功',
    GenerationError: '生成出错',
    pleaseSelectSynthesizedAudio: '请选择合成音频',
    PleasePatientWaitForMinutesSynthesizeVideo: '合成预计需要2-10分钟时间，期间请不要离开页面，否则会中断任务',
    errorGeneratingVideo: '生成视频出错',
    videoSuccessfulPleaseCheckVideoInMyPro: '视频合成成功，请到我的项目里面查看视频',
    videoSynthesisFailed: '视频合成失败',
    UploadingPleaseWait: '正在上传请稍后',
    UploadingVideo: '语音上传中...',
    uploadSuccessful: '上传成功',
    uploadFailed: '上传失败',
    ThisImageDoesNotMeetStandardsCannotRecognized: '该图片不符合标准，无法识别',
    pleaseEnterTheCopy: '请输入文案',
    translationFailed: '翻译失败',
    VideoFacelifteError: '视频换脸出错',
    VideoFacelifteFailed: '视频换脸失败',
    VideoFacelifteSuccessful: '视频换脸成功',
    PleaseClickOnChangeFaceAgain: '换脸准备阶段失败，请重新点击换脸',
    TheCurrentTaskBusyPleaseWaitBeforeTryingAgain: '当前任务繁忙，请稍等半分钟再尝试',
    TheCurrentTaskHasBeenAddedToQueueForProcessingPleaseBePatientAndPatient: '当前任务已经加入队列处理，预计需要2-10分钟，请耐心等耐',
    TranslationFailed: '翻译失败',
    areYouSureDelete: '是否确定删除?',
    networkAnomaly: '网络异常',
    operateSuccessfully: '操作成功',
    operationFailure: '操作失败',
    submittedSuccessfully: '提交成功',
    successfullyDelete: '删除成功',
    failToDelete: '删除失败',
    Undeleted: '已取消删除!',
    PleaseEnterAccount: '请输入账号',
    PleaseEnterPassword: '请输入密码',
    SuccessfullyAddedAccount: '新增账号成功',
    FailedAddedAccount: '新增账号失败',
    SuccessfullyModifiedAccount: '修改密码成功',
    FailedModifiedAccount: '修改密码失败',
    CheckingVideoResourcesPleaseWait: '正在检查视频资源，请稍后',
    PleaseContactAdmaxPersonnel: '视频不规范，暂无法进行换脸操作，请联系Admax客服人员'
  },
  // 登录
  Login: {
    login: '登录',
    loginAccount: '登录账号',
    enterUserName: '输入用户名',
    enterPassword: '输入密码',
    userNameIsRequired: '用户名必填',
    passwordIsRequired: '密码必填',
    loginFailed: '登录失败',
  },
}
