import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import * as api from './api'
import './index.less'
import Element from 'element-ui'
import VueI8n from 'vue-i18n'
import axios from 'axios' // 配置请求的根路径
import '@/assets/styles/index.less'

// wn 国际化加入elementui组件
import enLocale from 'element-ui/lib/locale/lang/en' // 英语
import zhLocale from 'element-ui/lib/locale/lang/zh-CN' // 简体中文

Vue.config.productionTip = false
Vue.prototype.$api = api
axios.defaults.baseURL = '/api/'

Vue.use(VueI8n)

// 国际化
window.Vue = Vue
const language = localStorage.getItem('language')
const i18n = new VueI8n({
  locale: language || 'cn',
  messages: {
    cn: Object.assign(require('@/assets/i18n-locales/cn.js'), zhLocale),
    en: Object.assign(require('@/assets/i18n-locales/en.js'), enLocale),
  },
})
Vue.use(Element, {
  size: 'mini',
  zIndex: 3000,
  i18n: (key, value) => i18n.t(key, value)
})

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
