<template>
  <div class="WorkbenchWrapper">
    <div class="main">
      <div class="left">
       <div class="vioceTitle">
          <div  class="title">
            <el-input v-model="form.clips[0].name" :placeholder="showPlaceholder"
              prefix-icon="el-icon-edit">
            </el-input>
            <el-button :disabled="loading" :loading="loading"
                      type="primary" @click="handleDraft">
              {{ $t('SaveDraft') }}
            </el-button>
            <el-button :disabled="loading" :loading="loading"
                      type="primary" @click="handleExport">
              {{ $t('CompositeVideo') }}
            </el-button>
        </div>
        <div class="videoWrap">
          <p class="videoWrapType">
            <i @click="changeBg('#FFFFFF')" />
            <i @click="changeBg('#008000')" />
            <span @click="changeVideoType('y')" :class="{'BgActive' : form.ratio === '16:9'}"></span>
            <span @click="changeVideoType('x')" :class="{'BgActive' : form.ratio === '9:16'}"></span>
          </p>
          <el-row>
            <el-col :span="8" style="height: 100%;" v-show="form.ratio === '9:16'">
              <p v-for="(item, index) in audioList" :key="index" style="height: 20%;
                display: flex;
                align-items: center;
                justify-content: center;"
                v-show='index < 5'
              >
                <!-- <audio
                    ref="audio"
                    controlsList="nodownload nodirection noduration nofullscreen noplaybackrate"
                    :src="item.url" controls>
                  </audio> -->
                <audio-player
                  ref="audio"
                  :class="{'audio-box': true, 'active': activeAudioIndex === index}"
                  :fileurl="item.url"
                  :item='item'
                  @confirm='openVideo' />
              </p>
            </el-col>
            <el-col :span="form.ratio === '9:16' ? 16 : 24" :class="{'h100': form.ratio === '9:16'}" style="padding: 20px 20px 20px;
                  display: flex;
                  align-items: center;
                  justify-content: center;">
              <div
                class="mubu h100"
                :style="{backgroundColor: form.background}"
              >
              <!-- <el-image style="height: 80%;" :src='imgUrl' /> -->
                <!-- <drag-Img style="height: 100%; width: 100%;" :imgSrc='imgUrl' :form="form"></drag-Img> -->
                <drag-resize :parentW="parentW" :parentH='parentH' :imgSrc='imgUrl' :form="form" @confirm="handleDragResize" />
              </div>
            </el-col>
            <el-col style="height: 60px" v-show="form.ratio === '16:9'">
              <p v-for="(item, index) in audioList" :key="index" style="width: 20%;
                float: left;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 20px;
                box-sizing: border-box;"
                v-show='index < 5'
              >
                <audio-player
                  ref="audio"
                  :class="{'audio-box': true, 'active': activeAudioIndex === index}"
                  style="width: 100%"
                  :fileurl="item.url"
                  :item='item'
                  @confirm='openVideo' />
              </p>
            </el-col>
          </el-row>
        </div>
       </div>
       <el-divider class="dividerLine" />
       <div class="voice">
          <el-row class="voiceTab">
            <span>{{ $t('AIGeneratedVoice') }}</span>
            <el-upload
              class="upload-demo"
              :action="action"
              :headers="headers"
              :show-file-list="false"
              :before-upload='beforeUpload'
              :on-error='handleError'
              :on-success="handleSuccess"
              :disabled="upLoadType !== 0">
              <el-button size="small" type="primary" :class="{'successColor': upLoadType === 2, 'errorColor': upLoadType === 3,}">
                {{ upLoadType === 0 ? upLoadType0 : upLoadType === 1 ? upLoadType1 : upLoadType === 2 ? upLoadType2 : upLoadType3 }}
                {{ (upLoadType === 2 || upLoadType === 3) ? (AiTime + 's') : '' }}
              </el-button>
            </el-upload>
          </el-row>
          <el-card class="box-card" shadow="never">
            <el-row class="voiceWarp displayFlex">
              <el-col class="voiceAI" :span="16">
                <el-input
                  type="textarea"
                  :placeholder="$t('PlaceholderText.pleaseEnterTheContent')"
                  v-model="speechData.content"
                  maxlength="1000"
                  show-word-limit
                />
                <p>
                  <el-image
                    style="width: 18px; height: 18px"
                    :src='translateUrl'
                    @click="handleTranslate"
                    fit="contain">
                  </el-image>
                  <el-image
                    style="width: 18px; height: 18px"
                    :src='AIUrl'
                    @click="handleAI"
                    fit="contain">
                  </el-image>
                </p>
              </el-col>
              <el-col class="voiceDivider" :span="2">
                <el-divider direction="vertical"></el-divider>
              </el-col>
              <el-col class="voiceLanguage" :span="6">
                <div class="voiceLanguageTop" @click="openSoundSelectDialog()">
                  <el-card shadow="hover">
                    <el-row>
                      <!-- <el-col :span="4">
                        <el-image
                          style="width: 30px; height: 20px"
                          :src="voice.url"
                          fit="contain">
                        </el-image>
                      </el-col>
                      <el-col :span="19" :offset="1"> -->
                      <el-col>
                        <p>
                          {{ voice.ShortName }}
                        </p>
                        <p>
                          <!-- <el-tag>
                              {{ voice.Gender }}
                          </el-tag>
                          <el-tag>
                              {{ voice.language }}
                          </el-tag>
                          <el-tag v-for="(list, index1) in voice.StyleList" :key="'style' + index1">
                              {{ list }}
                          </el-tag>
                            <el-tag v-for="(list, index1) in voice.RolePlayList" :key="'role' + index1">
                                {{ list }}
                            </el-tag> -->
                          <span>
                              {{ voice.Gender }}
                          </span>
                          <span>
                              {{ voice.language }}
                          </span>
                          <span v-if='voice.style'>
                              {{ voice.style }}
                          </span>
                          <span v-if='voice.role'>
                             {{ voice.role }}
                          </span>
                        </p>
                      </el-col>
                    </el-row>
                  </el-card>
                </div>
                <div class="voiceLanguageCenter">
                  <div class="block">
                    <el-row>
                      <el-col :span="4">{{ $t('SpeechSpeed') }}</el-col>
                      <el-col :span="20">
                        <el-slider v-model="speechData.rate" :min="1.0" :max="2.0" :step="0.1" show-input :show-input-controls="false"></el-slider>
                      </el-col>
                    </el-row>
                  </div>
                </div>
                <div class="voiceLanguageBottom">
                  <el-button
                        type="primary" @click="clearSpeech">
                    {{ $t('ClearContent') }}
                  </el-button>
                  <el-button :disabled="loading" :loading="loading"
                            type="primary" @click="handleSpeech">
                    {{ $t('SyntheticAudio') }}
                  </el-button>
                </div>
              </el-col>
            </el-row>
          </el-card>
       </div>
      </div>
      <div class="right">
        <RoleSelect :language='data.languages' :avatars='data.avatars' :talkingPhoto='data.talkingPhoto' @confirm="handleConfirm" @confirmUpload="handleConfirmUpload"/>
      </div>
    </div>
    <sound-select-dialog v-model='SoundSelectVisible' :langName='speechData.name' :language='data.languages' @confirm="handleSoundConfirm" />
    <translate-dialog v-model='speechVisible' :viocelang="viocelang" :speechData='speechData' @confirm="handleTranslateConfirm" />
    <ai-dialog v-model='AIVisible' :speechData='speechData' @confirm="handleAIConfirm" />
    <video-item-dialog v-model='videoDialogVisible' :videoItem='videoItem' @delConfirm="handleVideoConfirm" />
  </div>
</template>

<script>
import RoleSelect from '@/components/RoleSelect.vue'
import SoundSelectDialog from '@/components/SoundSelectDialog.vue'
import TranslateDialog from '@/components/TranslateDialog.vue'
import AiDialog from '@/components/AiDialog.vue'
import VideoItemDialog from '@/components/VideoItemDialog.vue'
// import DragImg from '@/components/DragImg.vue'
import AudioPlayer from '@/components/AudioPlayer.vue'
import DragResize from '@/components/DragResize.vue'
// import DragResizeX from '@/components/DragResizeX.vue'
// import DragResizeY from '@/components/DragResizeY.vue'

export default {
  name: 'WorkbenchWrapper',
  data () {
    return {
      imgUrl: '',
      translateUrl: require('@/assets/img/translate.png'),
      AIUrl: require('@/assets/img/AI.png'),
      topTip: '',
      showPlaceholder: this.$t('PlaceholderText.pleaseEnterTitle'),
      form: {
        ratio: '16:9',
        test: true,
        version: 'v1alpha',
        caption_open: false,
        background: '#FFFFFF',
        clips: [
          {
            name: '', // 主题
            id: '', // 必填
            preview: '',
            style: 'normal',
            type: '0',
            input_audio: '', // 非必填 url
            // voice_id: '', // 非必填
            project_id: '',
            // input_text: '', // 非必填
            // audio_list: [],
            //
            // avatar_style: 'normal',
            offset: {
              y: 0,
              x: 0
            },
            scale: 1,
          }
        ],
      },
      active: 0,
      loading: false,
      voice: {
        ShortName: '',
        language: '',
        style: '',
        role: '',
      },
      viocelang: '', // 选择的语种
      SoundSelectVisible: false,
      data: {
        avatars: [],
        languages: [],
        talkingPhoto: []
      },
      speechVisible: false,
      AIVisible: false,
      speechData: {
        lang: '',
        name: '',
        role: '',
        style: '',
        rate: 1,
        content: '',
      },
      audioList: [],
      videoDialogVisible: false,
      videoItem: {},
      activeAudioIndex: '',
      parentW: '', // 使用此参数，您可以设置组件的边界区域，并在实时调整大小时使用它
      parentH: '',
      upLoadType0: this.$t('ManuallyUploadVoice'),
      upLoadType1: this.$t('HintText.UploadingVideo'),
      upLoadType2: this.$t('HintText.uploadSuccessful'),
      upLoadType3: this.$t('HintText.uploadFailed'),
      upLoadType: 0, // 0正常 1 上传中 2 上次成功  3上传失败
      AiTime: '' // 上传倒计时
    }
  },
  components: {
    RoleSelect,
    SoundSelectDialog,
    TranslateDialog,
    AiDialog,
    VideoItemDialog,
    // DragImg,
    AudioPlayer,
    DragResize,
    // DragResizeX,
    // DragResizeY
  },
  computed: {
    host () {
      return process.env.NODE_ENV === 'production' ? '/digital' : 'http://144.34.180.209/digital'
    },
    action () {
      return `${this.host}/api/v1/materials/voices/upload`
    },
    headers () {
      const token = localStorage.getItem('token')
      return { Authorization: `Bearer ${token}` }
    },
    getVideoItem () {
      return this.$store.getters.videoItem || {}
    }
  },
  async mounted () {
    if (this.$route.params.type === 'detail' && this.getVideoItem?.clips?.length > 0) {
      // 处理后端没返回的数据  取默认值
      if (!this.getVideoItem.clips[0].offset) {
        this.getVideoItem.clips[0].offset = this.form.clips[0].offset
      } else {
        this.getVideoItem.clips[0].offset.x = Number(this.getVideoItem.clips[0].offset.x) || 0
        this.getVideoItem.clips[0].offset.y = Number(this.getVideoItem.clips[0].offset.x) || 0
      }
      this.getVideoItem.clips[0].scale = Number(this.getVideoItem.clips[0].scale) || 1
      this.getVideoItem.clips[0].scale = this.getVideoItem.clips[0].scale || this.form.clips[0].scale
      this.form = Object.assign({}, this.form, this.getVideoItem)
      this.imgUrl = this.form.clips[0].preview
      if (this.form.clips[0].audio_list?.length > 0) {
        this.audioList = this.form.clips[0].audio_list.map(e => {
          return {
            project_id: e.projectId,
            name: e.name,
            url: e.audio,
            content: e.text,
            Gender: e.Gender,
            language: e.language,
            role: e.role,
            style: e.style
          }
        })
        this.form.clips[0].name = this.form.clips[0].name || ''
        this.speechData.content = this.form.clips[0].audio_list[0].text || ''
      }
    }
    this.handleCount()
    this.getAvatarsAndLanguage()
  },
  methods: {
    async getAvatarsAndLanguage () {
      try {
        const resq = await this.$api.getAvatarsAndLanguage()
        const res = resq.data
        this.data = res
        this.data = {
          avatars: res.avatars.filter(e => e.type === '0'),
          languages: res.languages,
          // talkingPhoto: res.avatars.filter(e => e.type === '1')
          talkingPhoto: res.avatars.filter(e => e.type === '1').reduce((arr, item) => {
            arr = [
              ...arr,
              Object.assign({}, item, ...item.children)
            ]
            return arr
          }, [])
        }
        this.data.avatars.forEach(e => {
          e.url = e.children[0].url.replace('files.movio.la', 'files.heygencdn.com') || ''
          e.children.forEach(item => {
            item.url = item.url.replace('files.movio.la', 'files.heygencdn.com') || ''
          })
        })
        // 草稿进来的不取默认值
        if (!this.$route.params.type) {
          this.imgUrl = this.data.avatars[0].children[0].url || ''
          this.form.clips[0].id = this.data.avatars[0].children[0].name || ''
        }
        this.handleSoundConfirm(this.data.languages.filter(e => e.language.includes('English'))[0])
      } catch (e) {
      }
    },
    // 切换背景
    changeBg (type) {
      this.form.background = type
    },
    async handleDraft () {
      if (this.loading) return
      try {
        if (!this.form.clips[0].name) {
          return this.$message.error(this.$t('HintText.pleaseEnterVideoTitle'))
        }
        this.loading = true
        try {
          const obj = Object.assign({}, this.form)
          obj.clips[0].audio_list = this.audioList.map(e => {
            return {
              projectId: this.form.clips[0].project_id,
              // name: obj.clips[0].name,
              name: e.name,
              audio: e.url,
              text: e.content,
              language: e.language,
              Gender: e.Gender,
              role: e.role,
              style: e.style,
            }
          })
          obj.clips[0].preview = this.imgUrl || ''
          const res = await this.$api.getProjects(obj)
          this.form.clips[0].project_id = res.data || ''
          this.loading = false
          this.$message.success(this.$t('HintText.saveSuccessfully'))
        } catch (error) {
          // this.$message.error(this.$t('HintText.failToSave'))
          this.$message.error(error.response.data.msg || this.$t('HintText.failToSave'))
          this.loading = false
        }
      } catch (e) {
        this.loading = false
        console.log(e)
      }
    },
    clearSpeech () {
      this.speechData.content = ''
      this.speechData.rate = 1
    },
    async handleSpeech () {
      if (!this.speechData.name) return this.$message.error(this.$t('HintText.pleaseSelectLanguage'))
      if (!this.speechData.content) return this.$message.error(this.$t('HintText.pleaseEnterTheContent'))
      if (this.loading) return
      try {
        this.loading = true
        const obj = Object.assign({}, this.speechData, {
          rate: Number(this.speechData.rate) === 1 ? 10 : Math.round((this.speechData.rate - 1) * 100)
        })
        // 后端说语速为0 是 也默认传10
        const resq1 = await this.$api.speech(obj)
        const res1 = resq1.data
        this.audioList.push(Object.assign({}, obj, {
          url: res1,
          name: this.speechData.name,
          rate: this.speechData.rate,
          style: this.speechData.style,
          role: this.speechData.role,
        }))
        this.activeAudioIndex = this.audioList.length - 1
        this.form.clips[0].input_audio = res1
        this.$message.success(this.$t('HintText.SuccessfullyGenerated'))
        this.loading = false
      } catch (error) {
        this.loading = false
        this.$message.error(error.response.data.msg || this.$t('HintText.GenerationError'))
      }
    },
    async handleExport () {
      if (this.loading) return
      let falg = false
      try {
        if (!this.form.clips[0].name) {
          return this.$message.error(this.$t('HintText.pleaseEnterVideoTitle'))
        }
        if (!this.form.clips[0].input_audio) {
          return this.$message.error(this.$t('HintText.pleaseSelectSynthesizedAudio'))
        }
        this.form.clips[0].preview = this.imgUrl || ''
        this.loading = true
        try {
          const res = await this.$api.generateVideo(this.form)
          // this.$message(this.$t('HintText.PleasePatientWaitForMinutesSynthesizeVideo'))
          this.$message({
            duration: '12000',
            message: this.$t('HintText.PleasePatientWaitForMinutesSynthesizeVideo')
          })
          const timer = setInterval(async () => {
            try {
              const res1 = await this.handleLunxun(res.data || '')
              // const res1 = resLunxun.data
              if (res1.status === 'fail') {
                this.$message.close()
                this.$message.error(this.$t('HintText.errorGeneratingVideo'))
                this.loading = false
              }
              if (res1.status === 'completed' || res1.status === 'fail') {
                clearInterval(timer)
                this.loading = false
              }
              if (res1.status === 'completed') {
                falg = true
                this.$message.close()
                this.$message.success(this.$t('HintText.videoSuccessfulPleaseCheckVideoInMyPro'))
              }
            } catch (error) {
              // 后端接口轮询逻辑 文档 成功后还会返回失败  这时候就不返回失败了
              if (falg) {
                this.$message.close()
                this.$message.error(error.response.data.msg || this.$t('HintText.videoSynthesisFailed'))
              }
              clearInterval(timer)
              this.loading = false
            }
          }, 6000)
        } catch (error) {
          this.$message.error(error.response.data.msg || this.$t('HintText.errorGeneratingVideo'))
          this.loading = false
        }
      } catch (e) {
        console.log(e)
      }
    },
    // 切换视频类型
    changeVideoType (type) {
      if (type === 'x') {
        this.form.ratio = '16:9'
      } else {
        this.form.ratio = '9:16'
      }
      this.form.clips[0].scale = 1
      this.form.clips[0].offset = {
        x: 0,
        y: 0
      }
      this.$forceUpdate()
      this.handleCount()
    },
    handleTranslate () {
      this.speechVisible = true
    },
    handleAI () {
      this.AIVisible = true
    },
    beforeUpload () {
      this.upLoadType = 1
    },
    handleError () {
      this.upLoadType = 3
      this.setTimeoutAi()
    },
    handleSuccess (response, file, fileList) {
      this.audioList.push(Object.assign({}, {
        name: this.voice.ShortName,
        url: response.data,
        content: ''
      }))
      this.activeAudioIndex = this.audioList.length - 1
      this.form.clips[0].input_audio = response.data
      this.upLoadType = 2
      this.setTimeoutAi()
    },
    setTimeoutAi () {
      let time = 3
      this.AiTime = time
      const timer = setInterval(() => {
        time--
        this.AiTime = time
        if (time === 0) {
          clearInterval(timer)
          this.upLoadType = 0
        }
      }, 1000)
    },
    async handleLunxun (v) {
      try {
        const res = await this.$api.materialsVideo({
          videoId: v,
          videName: this.form.clips[0].name,
          background: this.form.background,
          ratio: this.form.ratio
        })
        return res.data || ''
      } catch (e) {
        console.log(e)
      }
    },
    handleCount () {
      if (this.form.ratio === '16:9') {
        this.parentW = 640
        this.parentH = this.parentW * 9 / 16
        this.$el.querySelector('.mubu').style.width = this.parentW + 'px'
        this.$el.querySelector('.mubu').style.height = this.parentH + 'px'
        this.$el.querySelector('.mubu').style.transform = `scale(${1})`
      } else {
        this.parentW = 360
        this.parentH = this.parentW * 16 / 9
        this.$el.querySelector('.mubu').style.width = this.parentW + 'px'
        this.$el.querySelector('.mubu').style.height = this.parentH + 'px'
        document.getElementsByClassName('mubu')[0].style.transform = `scale(${0.7})translate(${-45}%, ${20}px)`
      }
    },
    handeRoleData (data) {
      this.handleCount()
      this.imgUrl = data.url
      this.form.clips[0].avatar_id = data.avatar_id || ''
      this.form.clips[0].talking_photo_id = data.talking_photo_id || ''
    },
    handleConfirm (data) {
      if (data.type === '0') {
        this.form.clips[0].id = data.children[0].name || ''
        this.form.clips[0].type = data.type
        this.imgUrl = data.children[0].url || data.url
      } else if (data.type === '1') {
        this.form.clips[0].id = data.id || ''
        this.form.clips[0].type = data.type
        this.imgUrl = data.children[0].url || ''
      } else {
        this.form.clips[0].id = data.name || ''
        this.form.clips[0].type = '0'
        this.imgUrl = data.url || ''
      }
    },
    handleConfirmUpload (data) {
      this.getAvatarsAndLanguage()
    },
    openSoundSelectDialog () {
      this.SoundSelectVisible = true
    },
    handleSoundConfirm (data) {
      this.speechData = Object.assign({}, this.speechData, {
        lang: data.Locale,
        language: data.language,
        name: data.ShortName,
        Gender: data.Gender,
        role: data.role,
        style: data.style,
      })
      this.viocelang = data.language
      this.voice = Object.assign({}, this.voice, {
        language: data.language,
        Gender: data.Gender,
        ShortName: data.ShortName,
        style: data.style,
        role: data.role,
      })
    },
    handleTranslateConfirm (data) {
      this.speechData = Object.assign({}, data)
    },
    handleAIConfirm (data) {
      this.speechData = Object.assign({}, this.speechData, {
        content: data.substring(0, 1000)
      })
    },
    openVideo (item) {
      this.videoDialogVisible = true
      this.form.clips[0].input_audio = item.url
      this.videoItem = item
      this.activeAudioIndex = this.audioList.findIndex(e => e.url === item.url)
    },
    handleVideoConfirm (data) {
      const index = this.audioList.findIndex(e => e.url === data.url)
      this.audioList = this.audioList.filter(e => e.url !== data.url)
      if (this.audioList.length > 0 && this.audioList.length > index) {
        this.form.clips[0].input_audio = this.audioList[index].url
      } else if (this.audioList.length > 0 && this.audioList.length <= index) {
        this.form.clips[0].input_audio = this.audioList[this.audioList.length - 1].url
        this.activeAudioIndex = this.audioList.length - 1
      } else {
        this.form.clips[0].input_audio = ''
        this.activeAudioIndex = 0
      }
    },
    // 图片的放大 拖动
    handleDragResize (data) {
      this.form.clips[0].scale = data.clips[0].scale
      this.form.clips[0].offset = data.clips[0].offset
    },
  }
}
</script>

<style scoped lang="less">
.WorkbenchWrapper {
  display: flex;
  flex-direction: column;
  height: calc(100% - 40px);
  overflow-y: auto;
  .main {
    display: flex;
    flex: 1;
    .left {
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      .vioceTitle {
        padding: 10px 20px 0;
        .title {
          text-align: right;
          margin: 5px 0 10px;
          .el-input {
            float: left;
            width: 50%;
          }
          /deep/ .el-input__prefix {
            // left: 112px;
            color: #8BA0BD;
            i {
              font-size: 18px;
            }
          }
          /deep/ .el-input__inner {
            font-size: 20px;
            font-family: SourceHanSansCN-Bold, SourceHanSansCN;
            font-weight: bold;
            color: #094391;
            border: none;
            // padding-left: 10px;
            &::placeholder {
              color: #094391;
            }
            /* 谷歌 */
            &::-webkit-input-placeholder {
              color: #094391;
            }
            /* 火狐 */
            &:-moz-placeholder {
              color: #094391;
            }
            /*ie*/
            &:-ms-input-placeholder {
              color: #094391;
            }
          }
          /deep/.el-button {
            padding: 7px 11px;
            font-weight: bold;
            span {
              vertical-align: middle;
              display: inherit;
            }
            &:nth-of-type(1) {
              background: #729CD5;
            }
            &:nth-of-type(2) {
              background: #094391;
            }
          }
        }
        .videoWrap {
          height: 510px;
          background: #F7F8FA;
          border-radius: 10px;
          overflow: hidden;
          &Type {
            padding: 20px 30px 10px;
            display: flex;
            align-items: center;
            justify-content: right;
            position: relative;
            z-index: 999;
            i {
              box-sizing: border-box;
              display: inline-block;
              width: 16px;
              height: 16px;
              border-radius: 50%;
              position: absolute;
              cursor: pointer;
              &:nth-of-type(1) {
                background: #FFFFFF;
                border: 2px solid #094391;
                left: 30px;
              }
              &:nth-of-type(2) {
                background: #177F0A;
                border: 2px solid #1891AC;
                left: 56px
              }
            }
            span {
              border: 2px solid #094391;
              width: 12px;
              height: 18px;
              border-radius: 3px;
              cursor: pointer;
              box-sizing: border-box;
              &.BgActive {
                opacity: .3
              }
            }
            span:nth-of-type(2) {
              height: 12px;
              width: 18px;
              margin-left: 25px;
              position: relative;
              &::before {
                display: block;
                content: '';
                width: 0;
                height: 20px;
                border: 1px solid #DCE2EB;
                position: absolute;
                top: -5px;
                left: -13px;
              }
            }
          }
          >.el-row {
            height: 430px;
            margin-top: -20px;
          }
          .audio-box {
            // width: 80%;
            width: 190px;
            opacity: 0.5;
            cursor: pointer;
            &.active {
              opacity: 1;
            }
          }
          .mubu {
            width: 640px;
            height: 360px;
            // width: 100%;
            overflow: hidden;
            position: relative;
            box-shadow: 0px 6px 10px 0px rgba(153,164,179,0.19);
            border-radius: 10px;
            // img {
            //   border-radius: 5px;
            //   outline: none;
            //   border: none;
            //   position: absolute;
            //   left: 50%;
            //   transform: translateX(-50%);
            //   top: 0;
            //   //margin-top: 20px;
            // }
          }
        }
        .export {
          margin-bottom: 20px;
          display: flex;
          justify-content: flex-end;
        }
      }
      .dividerLine {
        margin: 16px 0 10px;
      }
      .voice {
        /deep/.box-card {
          margin: 5px 20px 0;
          border-radius: 10px;
          border: 1px solid #DCDFE6;
          >.el-card__body {
            padding: 0;
          }
        }
        &Tab {
          display: flex;
          color: #094391;
          align-items: center;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
          padding: 4px 0;
          span {
            padding: 0 20px;
            cursor: pointer;
            font-size: 18px;
          }
          .upload-demo {
            .el-button {
              background: none;
              border: none;
              color: #094391;
              font-family: SourceHanSansCN-Bold, SourceHanSansCN;
              font-weight: bold;
              font-size: 16px;
              opacity: .4;
              &.successColor {
                color: green;
              }
              &.errorColor {
                color: red;
              }
            }
          }
        }
        &Warp {
          padding: 0px;
          display: flex;
          height: 240px;
          .voiceAI {
            flex: 1;
            padding-bottom: 20px;
            /deep/.el-textarea__inner {
              height: 180px!important;
              // max-height: 200px!important;
              border: none;
              resize: none;
              padding-bottom: 20px
            }
            /deep/ .el-input__count {
              bottom: -24px;
            }
            position: relative;
            p {
              position: absolute;
              bottom: -8px;
              left: 20px;
              .el-image {
                margin-right: 10px;
                cursor: pointer;
              }
            }
          }
          .voiceDivider {
            width: 45px;
            text-align: right;
            .el-divider--vertical {
              min-height: 200px;
            }
          }
          .voiceLanguage {
            width: 310px;
            padding: 20px 25px;
            &Top {
              height: 70px;
              .el-card {
                cursor: pointer;
                height: 100%;
                border-radius: 10px;
                background: #F3F8FF;
                border: none;
                /deep/.el-card__body {
                  padding: 10px 15px;
                }
                .el-row {
                  display: flex;
                  align-items: center;
                  color: #333333;
                  .el-col {
                    p:nth-of-type(1) {
                      font-size: 16px;
                      font-weight: Bold;
                    }
                    p:nth-of-type(2) {
                      font-size: 14px;
                      line-height: 28px;
                      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                      font-weight: 400;
                      color: #939699;
                      span {
                        margin-right: 4px;
                      }
                    }
                  }
                }
              }
            }
            &Center {
              .el-row {
                display: flex;
                align-items: center;
                padding: 20px 0;
                font-size: 16px;
                font-family: SourceHanSansCN-Bold, SourceHanSansCN;
                font-weight: bold;
                color: #333333;
                /deep/.el-slider {
                  .el-slider__runway.show-input {
                    margin-right: 36px;
                    margin-left: 15px;
                  }
                  .el-slider__input {
                    width: 24px;
                    .el-input__inner {
                      border: none;
                      font-size: 16px;
                      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
                      font-weight: bold;
                      padding: 0;
                    }
                  }
                }
              }
            }
            &Bottom {
              display: flex;
              .el-button {
                flex: 1;
                // line-height: 34px;
                padding: 0;
                font-weight: bold;
                padding: 7px 11px;
                &:nth-of-type(1) {
                  // height: 34px;
                  background: #729CD5;
                }
                &:nth-of-type(2) {
                  // height: 34px;
                  background: #094391;
                }
              }
            }
          }
        }
      }
    }
    .right {
      width: 382px;
      // padding: 15px 20px 20px;
      box-sizing: border-box;
      border-left: 1px solid #DCDFE6
    }
  }
  .displayFlex {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .h100 {
    height: 100%;
  }
}
</style>
