<template>
  <div class="music" style=" width: 640px; height: 360px; position: relative">
    <VueDragResize
      ref='dragResize'
      v-if="vw > 0"
      :aspectRatio="true"
      :parentW='pW'
      :parentH='pH'
      :w="vw"
      :h='vh'
      v-on:resizing="resize"
      v-on:dragging="resize"
      :x="offset.x"
      :y="offset.y"
      :parentLimitation="true"
      :sticks="['tl','tr','bl','br']"
    >
      <!--将这个div的宽高动态设置==VueDrangResize的宽高这样可实时完成缩放-->
      <div class="box" ref="myImg" :style="{ width: + vw + 'px', height: +vh + 'px' }">
      <!-- <div class="box" ref="myImg"> -->
        <img
          :src="imgSrc"
          v-if='imgSrc'
          style="width: 100%; height: 100%"
        />
        <!-- <el-image
          style="width: 100%; height: 100%"
          :src="imgSrc"
          fit="fill"></el-image> -->
      </div>
    </VueDragResize>
    <!-- <div>
      <p>{{ vw }} х {{ vh }}</p>
      <p>{{ top }} х {{ left }}</p>
    </div> -->
  </div>
</template>

<script>
import VueDragResize from 'vue-drag-resize'
import $ from 'jquery'
export default {
  name: 'DragResize',
  components: {
    VueDragResize
  },
  data () {
    // 这里存放数据
    return {
      pW: 640,
      pH: 360,
      vw: 0,
      vh: 0,
      top: 0,
      left: 0,
      parentScaleX: 0.5,
      parentScaleY: 0.5,
      offset: {
        x: 0,
        y: 0
      }
    }
  },
  props: {
    parentW: {
      trpe: Number,
      default: 0
    },
    parentH: {
      trpe: Number,
      default: 0
    },
    imgSrc: {
      trpe: String,
      default: ''
    },
    form: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  watch: {
    parentW (val) {
      if (val) {
        document.getElementsByClassName('music')[0].style.width = this.parentW + 'px'
        document.getElementsByClassName('music')[0].style.height = this.parentH + 'px'
        // this.$nextTick(function () {
        console.log('form', val, this.form, this.parentH, this.form.clips[0].scale)
        this.pW = this.parentW
        this.pH = this.parentH
        this.vw = 0
        this.setImgSize()
      }
    },
    imgSrc (val) {
      console.log('val')
      this.vw = 0
      this.setImgSize()
    }
    // info: {
    //   handler (newVal) {
    //     console.log(newVal)
    //   },
    //   deep: true
    // }
  },
  async created () {
    // this.vh = 640 * 9 / 16
    // this.vw = this.vh
  },
  mounted () {
  },
  methods: {
    async setImgSize () {
      if (this.form.ratio === '16:9') {
        const imgSize = await this.getImgInfo(this.imgSrc)
        console.log('imgSize16:9', imgSize)
        console.log('im.offset', this.form.clips[0].offset.x, this.form.clips[0].offset.y, this.form.clips[0].scale)
        const ratio = 360 / imgSize.height
        this.vh = 360 * this.form.clips[0].scale
        this.vw = imgSize.width * ratio * this.form.clips[0].scale
        this.offset = {
          x: (this.form.clips[0].offset.x + 1) * (this.pW / 2) - this.vw / 2,
          y: (this.form.clips[0].offset.y + 1) * (this.pH / 2) - this.vh / 2,
        }
        console.log('imgSize16.offset', this.vh, this.vw)
        console.log('this.offset', this.offset.x, this.offset.y)
        if (this.$refs.myImg) {
          console.log('$(.box)', $('.box'), $('.box').width(), $('.box')[0].parentNode)
          $('.box').css({
            width: this.vw,
            height: this.vh
          })
          $('.box')[0].parentNode.style.width = this.vw + 'px'
          $('.box')[0].parentNode.style.height = this.vh + 'px'
        }
        this.$forceUpdate()
      } else {
        console.log('9:16')
        console.log('im.offset', this.form.clips[0].offset.x, this.form.clips[0].offset.y, this.form.clips[0].scale)
        const imgSize = await this.getImgInfo(this.imgSrc)
        const ratio = 360 / imgSize.width
        this.vw = 360 * this.form.clips[0].scale
        this.vh = imgSize.height * ratio * this.form.clips[0].scale
        this.offset = {
          x: (this.form.clips[0].offset.x + 1) * (this.pW / 2) - this.vw / 2,
          y: (this.form.clips[0].offset.y + 1) * (this.pH / 2) - this.vh / 2,
        }
        console.log('this.offset', this.offset)
        if (this.$refs.myImg) {
          console.log('$(.box)', $('.box'), $('.box').width(), $('.box')[0].parentNode)
          $('.box').css({
            width: this.vw,
            height: this.vh
          })
          $('.box')[0].parentNode.style.width = this.vw + 'px'
          $('.box')[0].parentNode.style.height = this.vh + 'px'
        }
        this.$forceUpdate()
      }
    },
    // resize (newRect) {
    //   debounce(async () => {
    //     this.resizex(newRect)
    //   }, 10)
    // },
    resize (newRect) {
      console.log('newRect', newRect, this.offset, this.form.clips[0].scale)
      if (newRect.width <= 0 || newRect.height <= 0) return
      this.vw = newRect.width
      this.vh = newRect.height
      this.top = newRect.top
      this.left = newRect.left
      // 记录中心点
      // 坐标中心点
      const p = {
        x: this.parentW / 2,
        y: this.parentH / 2
      }
      const imgP = {
        x: this.left + this.vw / 2,
        y: this.top + this.vh / 2
      }
      const offset = {
        x: this.returnFloat1((imgP.x - p.x) / p.x),
        y: this.returnFloat1((imgP.y - p.y) / p.y),
      }
      let scale = 1
      if (this.form.ratio === '16:9') {
        scale = this.returnFloat1(this.vh / this.pH)
      } else {
        scale = this.returnFloat1(this.vw / this.pW)
      }
      console.log('offset', offset, scale)
      const form = Object.assign({}, this.form)
      form.clips[0].offset = offset
      form.clips[0].scale = scale
      this.$emit('confirm', form)
    },
    // 保留一位小数 四舍五入
    returnFloat1 (value) {
      value = Math.round(parseFloat(value) * 10) / 10
      if (value.toString().indexOf('.') < 0) {
        value = value.toString() + '.0'
      }
      return Number(value)
    },
    getImgInfo (imgSrc) {
      // console.log('getImgInfo', imgSrc)
      const img = new Image()
      const imgPromise = new Promise((resolve, reject) => {
        img.onload = function () {
          const width = img.width
          const height = img.height
          resolve({
            width,
            height
          })
        }
        img.src = imgSrc
        img.onerror = function () {
          // reject({ width: 360, height: 360 })
        }
      })
      return imgPromise
    },
    debounce (fn, delay = 300) { // 默认300毫秒
      let timer
      return function () {
        const args = arguments
        if (timer) {
          clearTimeout(timer)
        }
        timer = setTimeout(() => {
          fn.apply(this, args) // this 指向vue
        }, delay)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.inactive {
    width: 100%;
}
</style>
