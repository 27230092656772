import axios from './axios'
/* const files = require.context('.', true, /\.js/)
const modules = {}
files.keys().forEach(key => {
  if (key === './index.js') {
    return
  }
  modules[key.replace(/(^\.\/|\.js$)/g, '')] = files(key).default
}) */

export const getHome = (params) => {
  return axios.get('/game/home', { params })
}
export const login = (data) => {
  return axios.post('/api/v1/auth/login', data)
}
export const materialsVideos = (data) => {
  return axios.post('/api/v1/materials/videos', data)
}
export const uploadTalkingPhoto = (data, name) => {
  return axios.post(`/api/v1/materials/uploadTalkingPhoto?name=${name}`, data)
}
export const getAvatars = (params) => {
  return axios.get('/api/v1/materials/avatars', { params })
}
// 生成文案 翻译
// export const copyWriting = (params) => {
//   return axios.get('/api/v1/materials/copyWriting', { params })
// }
// 生成文案 翻译 (让改成了post)
export const copyWriting = (data) => {
  return axios.post('/api/v1/materials/copyWriting', data)
}

export const talkingPhotos = (params) => {
  return axios.get('/api/v1/materials/talkingPhotos', { params })
}
export const languages = (params) => {
  return axios.get('/api/v1/materials/languages', { params })
}
export const speech = (data) => {
  return axios.post('/api/v1/materials/speech', data)
}
export const voices = (data) => {
  return axios.get('/api/v1/materials/voices', data)
}
export const uploadAudio = (data) => {
  return axios.post('/api/v1/materials/voices/upload', data)
}
export const generateVideo = (data) => {
  return axios.post('/api/v1/materials/generateVideo', data)
}
export const materialsVideo = (params) => {
  return axios.get('/api/v1/materials/videos', { params })
}
// 我的项目
export const centerAvatars = (params) => {
  return axios.get('/api/v1/center/avatars', { params })
}
// 我的草稿
export const projectsDraft = (params) => {
  return axios.get('/api/v1/projects/page', { params })
}
// 我的草稿 详情
export const projectsDetail = (id) => {
  return axios.get(`/api/v1/projects/${id}`)
}
export const delVideos = (params) => {
  return axios.delete('/api/v1/materials/videos', { params })
}
export const getAvatarsAndLanguage = (params) => {
  return axios.get('/api/v1/materials/avatarsAndLanguages', { params })
}
// 上传视频(换脸用),调用/api/v1/center/avatars接口返回的数据中，如果assetId为空，需要调用此接口做手动上传
export const uploadVideo = (data) => {
  return axios.post('/api/v1/materials/uploadVideo', data)
}
// 准备换脸
export const getFaceSwapReady = (data) => {
  return axios.post('/api/v1/materials/faceswap/getReady', data)
}
// 换脸
export const uploadAndSwap = (data) => {
  return axios.post('/api/v1/materials/faceswap/uploadAndSwap', data)
}
// c查询 换脸
export const getFaceswap = (params) => {
  return axios.get('/api/v1/materials/faceswap', { params })
}
// 查询地区
export const getRegions = (params) => {
  return axios.get('/api/v1/regions', { params })
}
// 保存项目
export const getProjects = (data) => {
  return axios.post('/api/v1/projects', data)
}
// 查询 账号管理
export const getUsers = (params) => {
  return axios.get('/api/v1/users/page', { params })
}
// 查询 账号 企业信息
export const getCompany = (params) => {
  return axios.get('/api/v1/users/company', { params })
}
// 查询公司实体
export const getCompanyDetail = (id) => {
  return axios.get(`/api/v1/companies/${id}`)
}
// 添加用户
export const addUsers = (data) => {
  return axios.post('/api/v1/users', data)
}
// 更新用户
export const updateUsers = (data) => {
  return axios.put('/api/v1/users', data)
}
// 修改密码
export const resetPassword = (data) => {
  return axios.put('/api/v1/users/resetPassword', data)
}
// 删除账号信息
export const deleteUser = (id) => {
  return axios.delete(`/api/v1/users/${id}`)
}
// 联系我们
export const contact = (data) => {
  return axios.post('/api/v1/contact', data)
}
