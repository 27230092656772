<template>
  <el-dialog :title="$t('Translate')" class="translate"
    :show-close='false'
    :visible.sync="dialogVisible"
    center
    width="42%">
    <div slot="title">
    </div>
    <el-row>
      <el-col :span="24">
        <p class="viocelang">{{ $t('TranslationLanguage') }} <span>{{ viocelang }}</span> </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="11">
        <el-input
          type="textarea"
          :placeholder="$t('PlaceholderText.pleaseEnterTheContent')"
          v-model="data.content"
          maxlength="1000"
          show-word-limit
        >
        </el-input>
      </el-col>
      <el-col :span="2" style="cursor: pointer;" @click.native="handleGPT()">
        <!-- <i class="el-icon-right"></i> -->
        <el-button
          :disabled="loading"
          :loading="loading"
          icon="el-icon-right" circle>
        </el-button>
      </el-col>
      <el-col :span="11">
        <el-input
          type="textarea"
          v-model="textarea"
          maxlength="1000"
          show-word-limit
        >
        </el-input>
      </el-col>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit()">{{ $t('QuoteText') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>

export default {
  name: 'TranslateDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    viocelang: {
      type: String,
      default: ''
    },
    speechData: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  data () {
    return {
      dialogVisible: false,
      data: {},
      textarea: '',
      loading: false,
    }
  },
  watch: {
    value (val) {
      this.dialogVisible = val
      if (val) {
        console.log(val)
        this.data = Object.assign({}, this.speechData)
        this.textarea = ''
      }
      this.loading = false
    },
    dialogVisible (val) {
      this.$emit('input', val)
    }
  },
  computed: {
    host () {
      return process.env.NODE_ENV === 'production' ? '/digital' : 'http://144.34.180.209/digital/'
    },
    action () {
      return `${this.host}api/v1/materials/uploadTalkingPhoto`
    }
  },
  async mounted () {
    // await Promise.all([this.handlePhotos(), this.handleAvatar()])
  },
  methods: {
    async handleGPT () {
      try {
        if (this.loading) return
        this.loading = true
        const content = `${this.data.content}     翻译成${this.viocelang}`
        const res = await this.$api.copyWriting({
          content: content,
          // language: this.data.name
        })
        this.loading = false
        this.textarea = res.data
      } catch (error) {
        this.$message.error(error.response.data.msg || this.$t('HintText.TranslationFailed'))
        this.loading = false
      }
    },
    submit () {
      this.dialogVisible = false
      this.$emit('confirm', Object.assign({}, this.data, {
        content: this.textarea.substring(0, 1000)
      }))
    }
  }
}
</script>

<style scoped lang="less">
/deep/.el-dialog {
  background: url("~@/assets/img/soundBg.png") no-repeat;
  background-size: 100%  100%;
  padding: 10px 10px 0px;
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 25px 40px 20px;
  }
  .el-dialog__footer {
    padding: 0px 20px 30px
  }
  .el-textarea__inner {
    border-radius: 10px;
    border: none;
    resize: none;
    box-shadow: 0px 3px 9px 0px #E6EEF7;
  }
}
.el-row {
  display: flex;
  align-items: center;
  text-align: center;
  .el-col {
    /deep/.el-textarea__inner {
      height: 200px!important;
      max-height: 200px!important;
    }
    font-size: 20px;
    .viocelang {
      text-align: center;
      font-size: 18px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #094391;
      line-height: 18px;
      padding: 10px 20px 20px;
      span {
        color: #333333;
        margin-left: 10px;
      }
    }
  }
}
.dialog-footer {
  .el-button {
    font-size: 14px;
    font-weight: bold;
    background: #094391;
    padding: 7px 11px;
  }
}
</style>
