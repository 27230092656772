<template>
  <el-dialog
    title="音频"
    class="video"
    :show-close="false"
    :visible.sync="dialogVisible"
    center
    width="500px"
  >
    <div slot="title"></div>
    <p style="display: flex">
      <!-- <audio
        ref="audio"
        controlsList="nodownload nodirection noduration nofullscreen noplaybackrate"
        :src="videoItem.url"
        controls
      ></audio> -->
      <audio-player
        ref="audio"
        class="audio-box"
        :fileurl="videoItem.url"
        :item="videoItem"
        @confirm="openVideo"
      />
      <el-button class="delAudio" @click="delAudio()" icon="el-icon-delete">{{ $t('del') }}</el-button>
    </p>
    <el-row class='language'>
      <el-card shadow="never">
        <el-row>
          <el-col>
            <p class="languageName">
              {{ videoItem.name }}
            </p>
            <p class="languageTypeName">
              <span>
                              {{ videoItem.Gender }}
                          </span>
                          <span>
                              {{ videoItem.language }}
                          </span>
                          <span v-if='videoItem.style'>
                              {{ videoItem.style }}
                          </span>
                          <span v-if='videoItem.role'>
                             {{ videoItem.role }}
                          </span>
            </p>
          </el-col>
          <el-col class="languageSpeed" v-if="videoItem.rate">
            <p>{{ $t('SpeechSpeed') }}</p>
            <p>{{ videoItem.rate }}</p>
          </el-col>
        </el-row>
      </el-card>
    </el-row>
    <el-row>
      <el-card shadow="never">
        <el-row>
          <p class="content">
            {{ videoItem.content }}
          </p>
        </el-row>
      </el-card>
    </el-row>
    <!-- <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit()">引用文本</el-button>
      </span> -->
  </el-dialog>
</template>

<script>
import AudioPlayer from './AudioPlayer.vue'
export default {
  name: 'TranslateDialog',
  components: {
    AudioPlayer
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    videoItem: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      dialogVisible: false,
      data: {},
      textarea: '',
      loading: false
    }
  },
  watch: {
    value (val) {
      this.dialogVisible = val
      if (val) {
        console.log(val, this.videoItem)
      }
    },
    dialogVisible (val) {
      this.$emit('input', val)
    }
  },
  async mounted () {
    // await Promise.all([this.handlePhotos(), this.handleAvatar()])
  },
  methods: {
    openVideo (data) {},
    delAudio () {
      this.dialogVisible = false
      this.$emit('delConfirm', this.videoItem)
    },
    submit () {
      this.dialogVisible = false
    }
  }
}
</script>

<style scoped lang="less">
/deep/.el-dialog {
  background: url("~@/assets/img/soundBg.png") no-repeat;
  background-size: 100%  100%;
  padding: 10px 10px 0px;
  .el-dialog__header {
    display: none;
  }
  .el-card {
    border-radius: 10px;
    border: none;
    box-shadow: 0px 3px 9px 0px #E6EEF7;
  }
  .el-card__body {
    border-radius: 10px;
  }
}
.audio-box {
  // width: 80%;
  width: 335px;
  cursor: pointer;
  margin-right: 20px;
}
.language {
  margin: 20px 0;
  color: #333333;
  .el-row {
    display: flex;
    .el-col {
      background: #F3F8FF;
      border-radius: 10px;
      height: 70px;
      overflow: hidden;
      padding: 8px;
    }
    .el-col:nth-of-type(1) {
      flex: 1;
      margin-right: 20px;
      padding-left: 16px;
    }
  }
  &Name {
    font-size: 16px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    line-height: 36px;
  }
  &TypeName {
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    line-height: 24px;
    color: #939699;
  }
  &Speed {
    width: 70px;
    line-height: 28px;
    text-align: center;
    color: #333333;
    p:nth-of-type(1) {
      font-size: 14px;
      opacity: .5;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
    }
    p:nth-of-type(2) {
      font-size: 16px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
    }
  }
}
.content {
  font-size: 18px;
  font-family: Helvetica;
  color: #333333;
  line-height: 32px;
}
.delAudio {
  border-radius: 10px;
  background:#FF767B;
  box-shadow: 0px 6px 10px 0px rgba(153,164,179,0.19);
  color: #FFF;
  font-size: 14px;
  font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  font-weight: bold;
}
</style>
