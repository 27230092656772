// 英文
module.exports = {
  SaveDraft: 'Save Draft',
  CompositeVideo: 'Composite Video',
  AIGeneratedVoice: 'AI Generated Voice',
  ManuallyUploadVoice: 'Manually Upload Voice',
  SpeechSpeed: 'Speed',
  ClearContent: 'Clear Content',
  SyntheticAudio: 'Synthetic Audio',
  ChooseDigitalPerson: 'Choose Digital Person',
  All: 'All',
  More: 'More',
  ChooseAccessories: 'Choose Accessories',
  UploadPhotos: 'Upload Photos',
  SoundSquare: 'Sound Square',
  Sex: 'Sex',
  Languages: 'Languages',
  OralSpeechSounds: 'Accent',
  Role: 'Role',
  Style: 'Style',
  Other: 'Other',
  Male: 'Male',
  Female: 'Female',
  Translate: 'Translate',
  TranslationLanguage: 'Translation Language',
  AIIntellCopywritingGeneration: 'AI Intelligent Copywriting Generation',
  GenerateCopy: 'Generate Copy',
  UsingCopy: 'Using Copy',
  YourBrowserDoesNotSupportAudioPlayback: 'Your browser does not support audio playback',
  FaceTransplant: 'Facemap',
  QuoteText: 'Quote Text',
  Down: 'Down',
  Edit: 'Edit',
  MyProject: 'My Project',
  MyDraft: 'My Draft',
  RemainingAvailableTime: 'Remaining Available Time',
  Wherein: 'Wherein',
  WillBeOn: 'Will be on',
  Expire: 'Expire',
  AddAccount: 'Add Account',
  SerialNumber: 'Serial Number',
  AccountNumber: 'Account Number',
  Password: 'Password',
  Operate: 'Operate',
  ModifyAccountInfo: 'Modify Account Info',
  del: 'Delete',
  close: 'Close',
  confirm: 'Confirm',
  cancel: 'Cancel',
  AccountPassword: 'Account Password',
  OriginalAccount: 'Original Account',
  OriginalPassword: 'Original Password',
  NewAccount: 'New Account',
  NewPassword: 'New Password',
  User: 'User',
  Staging: 'Staging',
  Myproject: 'My project',
  AccountManagement: 'Account management',
  Hour: 'Hour',
  Minute: 'Minute',
  second: 'second',
  ThereIsCurrentlyNoAvailableDuration: 'There is currently no available duration (the package has expired)',
  PlaceholderText: {
    pleaseEnterTheContent: 'Please enter content',
    pleaseEnterTitle: 'Please enter title',
  },
  HintText: {
    hint: 'Hint',
    pleaseEnterVideoTitle: 'Please enter video title',
    saveSuccessfully: 'Saved successfully',
    failToSave: 'Failed to save',
    pleaseSelectLanguage: 'Please select a language',
    pleaseEnterTheContent: 'Please enter content',
    SuccessfullyGenerated: 'Generated successfully',
    GenerationError: 'Generation error',
    pleaseSelectSynthesizedAudio: 'Please select synthesized audio',
    PleasePatientWaitForMinutesSynthesizeVideo: 'The synthesis is expected to take 2-10 minutes. Please do not leave the page during this time, as it may interrupt the task',
    errorGeneratingVideo: 'Error generating video',
    videoSuccessfulPleaseCheckVideoInMyPro: 'Video synthesized successfully. Please check the video in My Project.',
    videoSynthesisFailed: 'Video synthesis failed',
    UploadingPleaseWait: 'Uploading, please wait',
    UploadingVideo: 'Uploading....',
    uploadSuccessful: 'Upload successful',
    uploadFailed: 'Upload failed',
    ThisImageDoesNotMeetStandardsCannotRecognized: 'This image does not meet the standards and cannot be recognized',
    pleaseEnterTheCopy: 'Please enter copy',
    translationFailed: 'Translation failed',
    VideoFacelifteError: 'Video facelift error',
    VideoFacelifteFailed: 'Video facelift failed',
    VideoFacelifteSuccessful: 'Video facelift successful',
    PleaseClickOnChangeFaceAgain: 'Face swap preparation failed. Please click "Change Face" again',
    TheCurrentTaskBusyPleaseWaitBeforeTryingAgain: 'The current task is busy. Please wait a moment before trying again',
    TheCurrentTaskHasBeenAddedToQueueForProcessingPleaseBePatientAndPatient: 'The current task has been added to the processing queue. Estimated time is 2-10 minutes. Please be patient.',
    TranslationFailed: 'Translation failed',
    areYouSureDelete: 'Are you sure you want to delete?',
    networkAnomaly: 'Network anomaly',
    operateSuccessfully: 'Operation successful',
    operationFailure: 'Operation failure',
    submittedSuccessfully: 'Submitted successfully',
    successfullyDelete: 'Successfully deleted',
    failToDelete: 'Failed to delete',
    Undeleted: 'Undeleted!',
    PleaseEnterAccount: 'Please enter account',
    PleaseEnterPassword: 'Please enter password',
    SuccessfullyAddedAccount: 'Account added successfully',
    FailedAddedAccount: 'Failed to add account',
    SuccessfullyModifiedAccount: 'Password modified successfully',
    FailedModifiedAccount: 'Failed to modify password',
    CheckingVideoResourcesPleaseWait: 'Checking video resources, please wait',
    PleaseContactAdmaxPersonnel: 'The video is not standardized and the face changing operation cannot be performed at the moment. Please contact Admax customer service personnel'
  },
  // Login
  Login: {
    login: 'Login',
    loginAccount: 'Login Account',
    enterUserName: 'Enter username',
    enterPassword: 'Enter password',
    userNameIsRequired: 'Username is required',
    passwordIsRequired: 'Password is required',
    loginFailed: 'Login failed',
  },
}
