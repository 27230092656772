<template>
  <div class="LoginWrapper">
    <div class="content">
      <div class="left">
        <img src="@/assets/screenshot-20230704-150605.png" alt="">
      </div>
      <div class="right">
        <div class="title">{{ $t('Login.loginAccount') }}</div>
        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" class="demo-ruleForm">
          <el-form-item class="item" prop="username">
            <el-input prefix-icon="el-icon-user" type="text" v-model="ruleForm.username" autocomplete="off" :placeholder="$t('Login.enterUserName')"></el-input>
          </el-form-item>
          <el-form-item class="item" prop="password">
            <el-input prefix-icon="el-icon-lock" type="password" v-model="ruleForm.password" autocomplete="off" :placeholder="$t('Login.enterPassword')" @keydown.enter.native="submitForm('ruleForm')"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button class="login" :loading="loading" :disabled="loading" type="primary" @click="submitForm('ruleForm')">{{ $t('Login.login') }}</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginWrapper',
  data () {
    return {
      loading: false,
      ruleForm: {
        username: '',
        password: '',
        // username: 'admin',
        // password: 'password',
      },
      rules: {
        username: [
          { required: true, trigger: 'blur', message: this.$t('Login.userNameIsRequired') }
          // { validator: validatePass, trigger: 'blur' }
        ],
        password: [
          { required: true, trigger: 'blur', message: this.$t('Login.passwordIsRequired') }
          // { validator: validatePass2, trigger: 'blur' }
        ],
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    if (localStorage.getItem('token')) {
      next('/home/workbench')
    } else {
      next()
    }
  },
  methods: {
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true
            const res = await this.$api.login({
              ...this.ruleForm
            })
            this.loading = false
            localStorage.setItem('token', res.data.access_token)
            localStorage.setItem('refresh_token', res.data.refresh_token)
            // 后端没有查询用户信息的 需求让把用户名直接取文本框输入的 暂时就暂存localStorage里吧
            localStorage.setItem('username', this.ruleForm.username)
            this.$store.commit('setAdmin', res.data.access_token)
            await this.$router.push('/home')
          } catch (error) {
            this.loading = false
            this.$message({
              message: error.response.data.msg || this.$t('Login.loginFailed'),
              type: 'error'
            })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
    }
  }
}
</script>

<style scoped lang="less">
.LoginWrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .left {
    height: 100%;
    img  {
      height: 100%;
    }
  }
  .content {
    width: 1200px;
    height: 480px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,.1);
    padding: 0 60px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .right  {
      width: 300px;
      .title {
        color: #0188F4;
        font-size: 22px;
        margin-bottom: 20px;
      }
      .el-form {
        width: 300px;
      }
      .item {
        margin-bottom: 35px;
      }
      .login {
        width: 100%;
      }
    }
  }
}
</style>
