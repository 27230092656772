<template>
  <div id="app" v-loading="globalLoading">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'app',
  components: {
  },
  provide () {
    return {
      hideGLoading: this.hideGLoading, // 全局loading关闭方法
      showGLoading: this.showGLoading, // 全局loading显示方法
    }
  },
  data () {
    return {
      globalLoading: false, // 全局loading状态
    }
  },
  methods: {
    hideGLoading () {
      this.globalLoading = false
    },
    showGLoading () {
      this.globalLoading = true
    }
  }
}
</script>

<style>
*{
  margin: 0;
  padding: 0;
}
#app, body, html {
  height: 100%;
  min-width: 1100px;
  /* overflow: hidden; */
  font-family: SourceHanSansCN;
}
</style>
