import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import Index from '@/views/Index.vue'
import Login from '@/views/Login.vue'
import Workbench from '@/views/Workbench.vue'
import Project from '@/views/Project.vue'
import AccountManage from '@/views/AccountManage/index.vue'
// import Page404 from '@/views/404.vue'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
VueRouter.prototype.replace = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/',
    name: 'index',
    component: Index
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView,
    redirect: '/home/workbench',
    children: [
      {
        path: 'workbench',
        name: 'workbench',
        component: Workbench,
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: 'project',
        name: 'project',
        component: Project,
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: 'accountManage',
        name: 'accountManage',
        component: AccountManage,
        meta: {
          requiresAuth: true,
        }
      },
    ],
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  // {
  //   path: '*',
  //   name: '404',
  //   component: Page404
  // },
  /* {
    path: '/',
    component: Layout,
    redirect: '/game-list',
    children: [
      {
        path: '/game-list',
        name: 'gameList',
        component: GameList,
      },
    ]
  }, */
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token')

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // 判断用户是否已经登录
    if (!token) {
      next({
        path: '/login',
        // query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next()
  }
  /* if (!token && to.path !== '/login') {
    next('/login')
  } else if (token && to.path === '/login') {
    next('/home')
  } else {
    next()
  } */
})

export default router
