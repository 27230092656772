<template>
  <div class="home-page">
    <div class="banner bg_one">
      <h1 class="title">多语言AI视频制作</h1>
      <div class="note">
        <p>只需要输入关键词, 即可创作AI口播视频</p>
        <p>为创作者提供赋能</p>
      </div>
      <el-button class="gradient-btn btn" @click="$router.push('/login')"
        >立即创作</el-button
      >
      <!-- 照片墙 -->
      <div class="photo-back">
        <div class="top">
          <img src="@/assets/home/photo-1.png" alt="avater" />
          <img src="@/assets/home/photo-2.png" alt="avater" />
          <img src="@/assets/home/photo-3.png" alt="avater" />
        </div>
        <div class="bottom">
          <img src="@/assets/home/photo-4.png" alt="avater" />
          <img src="@/assets/home/photo-5.png" alt="avater" />
          <img src="@/assets/home/photo-6.png" alt="avater" />
          <img src="@/assets/home/photo-7.png" alt="avater" />
        </div>
      </div>
    </div>
    <div class="banner bg_two">
      <h1 class="title">您完整的视频营销工具</h1>
      <div class="absoulte">
        <h3 class="title_sm">AI文案智能助手</h3>
        <div class="note">
          <p>通过输入关键词，即可获得AI工具人的帮助。</p>
          <p>系统会根据您的关键词，自动生成您所需文</p>
          <p>案，快速提高效率。</p>
        </div>
      </div>
    </div>
    <div class="banner bg_three">
      <div class="absoulte">
        <h3 class="title_sm">逼真的AI模特和语音</h3>
        <div class="note">
          <p>根据脚本内容，可以随意选择AI模特，120+</p>
          <p>种语言，可以切换不同场景下的人物对话。</p>
        </div>
      </div>
    </div>
    <div class="banner bg_four">
      <div class="absoulte">
        <h3 class="title_sm">使用方便快捷</h3>
        <div class="note">
          <p>不同的角色背景可供选择，提高工作效率。</p>
          <p>帮助您提升营销效果。</p>
        </div>
      </div>
    </div>
    <div class="banner bg_five" id="contacOurs">
      <div class="form-back">
        <img src="@/assets/home/bg-5.png" alt="合作" />
        <div class="form-box">
          <h1 class="title">联系我们</h1>
          <h3 class="title_sm">以便我们为您提供更优质的服务</h3>
          <el-form
            ref="formRef"
            :model="form"
            :rules="rules"
            label-width="90px"
          >
            <el-form-item label="公司名称:" prop="contact">
              <el-input v-model="form.contact" />
            </el-form-item>
            <el-form-item label="联系人:" prop="name">
              <el-input v-model="form.name" />
            </el-form-item>
            <el-form-item label="电话:" prop="mobile">
              <el-input v-model="form.mobile" />
            </el-form-item>
            <el-form-item label="邮箱:" prop="email">
              <el-input v-model="form.email" />
            </el-form-item>
            <el-form-item>
              <el-button class="gradient-btn btn" @click="onSubmit('formRef')"
                >提交</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IndexWrapper',
  data () {
    return {
      form: {
        contact: '',
        name: '',
        mobile: '',
        email: ''
      },
      rules: {
        contact: [
          { required: true, message: '请输入公司名称', trigger: 'blur' }
        ],
        name: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          // {
          //   pattern: /^1[3-9]\d{9}$/,
          //   message: '手机号格式不正确',
          //   trigger: 'blur'
          // }
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          // {
          //   pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
          //   message: '邮箱格式不正确',
          //   trigger: 'blur'
          // }
        ]
      }
    }
  },
  mounted () {
    // this.$el.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    // handleScroll () {
    //   // if (this.$el.scrollTop > 60) {
    //   //   this.$el.querySelector('.head').style.background = '#FFF'
    //   // } else {
    //   //   this.$el.querySelector('.head').style.background = 'transparent'
    //   // }
    //   this.$refs.indexWarp.scrollTo(0, 300)
    // },
    onSubmit (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            const res = await this.$api.contact(this.form)
            console.log('contact', res)
            this.$message.success(this.$t('HintText.submittedSuccessfully'))
          } catch (error) {
            console.log(error)
            this.$message.error(this.$t('HintText.operationFailure'))
          }
        }
      })
    },
  },
  beforeDestroy () {
    this.$el.removeEventListener('scroll', this.handleScroll)
  },
}
</script>

<style scoped lang="less">
.home-page {
  > .banner {
    width: 100%;
    user-select: none;

    .title {
      margin-top: 45px;
      font-size: 38px;
      text-align: center;
    }

    .title_sm {
      font-size: 24px;
      color: var(--text-default);
      font-weight: normal;
    }

    .note {
      margin-top: 20px;
      font-size: 14px;
      color: var(--text-default);
      p {
        line-height: 20px;
      }
    }
  }
  .bg_one {
    height: 570px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -40px;
    padding-top: 50px;
    background: url('@/assets/home/bg-1.png'),
      url('@/assets/home/icon-1.png') no-repeat right top / 20%,
      url('@/assets/home/icon-2.png') no-repeat left bottom / 25%;
    .note {
      text-align: center;
    }
    .btn {
      margin-top: 30px;
    }
    .photo-back {
      width: 800px;
      margin-top: 20px;
      .top {
        padding: 0 100px;
      }
      .top,
      .bottom {
        display: flex;
        justify-content: space-around;
      }
      img {
        flex-shrink: 0;
        width: 150px;
      }
    }
  }
  .bg_two {
    height: 600px;
    position: relative;
    background: url('@/assets/home/bg-2.png') no-repeat;
    background-size: 50%;
    background-position: center bottom 70px;
    .absoulte {
      position: absolute;
      top: 100px;
      left: 300px;
    }
  }
  .bg_three {
    height: 480px;
    position: relative;
    background: url('@/assets/home/bg-3.png') no-repeat, #f0f7ff;
    background-size: 50%;
    background-position: center bottom 10px;

    .absoulte {
      position: absolute;
      top: 50px;
      right: 250px;
    }
  }
  .bg_four {
    height: 530px;
    position: relative;
    background: url('@/assets/home/bg-4.png') no-repeat;
    background-size: 50%;
    background-position: center bottom 50px;
    .absoulte {
      position: absolute;
      top: 50px;
      left: 300px;
    }
  }
  .bg_five {
    position: relative;
    height: 660px;
    background: url('@/assets/home/icon-3.png') no-repeat top 30px left 160px /
        10%,
      url('@/assets/home/icon-4.png') no-repeat right 60px bottom 30px / 10%,
      #f0f7ff;
    .form-back {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-55%, -50%);
      width: 900px;
      height: 520px;
      margin-left: 100px;
      border-radius: 20px;
      background-color: #fff;
      img {
        width: 400px;
        position: absolute;
        top: 50%;
        left: -100px;
        transform: translateY(-50%);
      }

      .form-box {
        position: absolute;
        right: 120px;
        width: 300px;
        .title {
          text-align: left;
        }
        .title_sm {
          font-size: 20px;
        }

        .el-form {
          margin-top: 20px;
          .btn {
            margin-top: 50px;
            width: 85px;
          }
        }
      }
    }
  }
}
</style>
